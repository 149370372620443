import styled from '@emotion/styled';
import { TextInput } from '@mantine/core';
import { forwardRef } from 'react';

import { labelSmallCss, labelXSmallCss } from '~/components/typography';

//TODO: Create custom input component
const Input = forwardRef(({ styles, ...rest }, ref) => {
  const { input: inputStyles, label: labelStyles, ...restStyles } = styles || {};

  return (
    <Root
      ref={ref}
      styles={{
        input: {
          ...inputStyles,
        },
        label: {
          ...labelStyles,
        },
        ...restStyles,
      }}
      labelProps={{
        component: InputLabel,
      }}
      {...rest}
    />
  );
});

export default Input;

const Root = styled(TextInput)`
  input {
    height: 48px;
    ${labelSmallCss};
    background: ${({ theme }) => theme.app.colors.BG_NEUTRAL_WEAKEST};
    padding: 16px;
    border: 0;
    border-radius: 8px;

    &::placeholder {
      color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_WEAKEST};
    }
  }

  label {
    margin-bottom: 8px;
    font-weight: ${({ theme }) => theme.app.fontWeights.medium};
  }
`;

const InputLabel = styled.label`
  ${labelXSmallCss}
  font-weight: ${({ theme }) => theme.app.fontWeights.medium};
`;
