import { isFunction } from 'lodash-es';
import { useAnalytics as origUseAnalytics } from 'use-analytics';

import { flattenObject } from '~/utils';

function useAnalytics() {
  const origAnalytics = origUseAnalytics();

  return {
    identify: origAnalytics.identify,
    track: (eventName, payloadOrCallback, optionsOrCallback, callback) => {
      if (payloadOrCallback && !isFunction(payloadOrCallback)) {
        payloadOrCallback = flattenObject(payloadOrCallback, {
          skipArrayFlatten: true,
        });
      }
      return origAnalytics.track(eventName, payloadOrCallback, optionsOrCallback, callback);
    },
    reset: origAnalytics.reset,
    user: origAnalytics.user,
  };
}

export default useAnalytics;
