import CryptoJS from 'crypto-js';

import { CHATWOOT_IDENTITY_VALIDATION_KEY, CHATWOOT_WEBSITE_TOKEN } from '~/config';

export const generateCWIdentifierHash = (identifier) => {
  // Create HMAC using SHA256
  const hmac = CryptoJS.HmacSHA256(identifier, CHATWOOT_IDENTITY_VALIDATION_KEY);

  // Convert the HMAC to a hex string
  const hmacHex = hmac.toString(CryptoJS.enc.Hex);

  return hmacHex;
};

export const initLiveChat = () => {
  window.chatwootSettings = {
    hideMessageBubble: true,
    position: 'left',
    locale: 'en',
    type: 'standard',
    showUnreadMessageDialog: true,
  };

  const BASE_URL = 'https://app.chatwoot.com';
  const newScriptEl = document.createElement('script'),
    firstScriptEl = document.getElementsByTagName('script')[0];
  newScriptEl.src = BASE_URL + '/packs/js/sdk.js';
  newScriptEl.defer = true;
  newScriptEl.async = true;
  firstScriptEl.parentNode.insertBefore(newScriptEl, firstScriptEl);

  newScriptEl.onload = function () {
    window.chatwootSDK.run({
      websiteToken: CHATWOOT_WEBSITE_TOKEN,
      baseUrl: BASE_URL,
    });
  };
};
