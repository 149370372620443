import styled from '@emotion/styled';
import { Box, Flex } from '@mantine/core';

export const Root = styled(Box)`
  border-radius: 0 0 16px 16px;
  background-color: ${({ theme }) => theme.app.colors.BG_NEUTRAL_WEAKEST};
`;

export const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.app.colors.BG_NEUTRAL_WEAKEST};
  z-index: 2;
`;

export const Body = styled(Box)`
  overflow-y: auto;
  height: calc(90vh - 58px);
`;

export const TabPanelContainer = styled(Box)`
  margin-top: 24px;
  padding: 24px;
  padding-top: 0px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.app.colors.BG_NEUTRAL_WEAKEST};
`;

export const TabPanelContent = styled(Box)`
  padding: 24px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.app.colors.BG_SURFACE};

  & + & {
    margin-top: 24px;
  }
`;

export const NoResultsIllustrationWrapper = styled(Box)`
  width: 198px;
  height: 136px;
  flex-shrink: 0;
`;

export const HeaderIcon = styled(Box)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.app.colors.ICON_NEUTRAL_STRONG};
`;
