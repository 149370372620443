import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Avatar, Box, Flex, Menu, Switch, useMantineTheme } from '@mantine/core';
import { NavLink } from 'react-router-dom';
import { remove as removeCookie } from 'es-cookie';
import { IconLogout } from '@tabler/icons-react';
import { noop } from 'lodash-es';

import { useAppContext } from '~/contexts/app';
import { useIsTabletOrMobileMedia } from '~/hooks';
import { isProductionHostname, isUpraisedEmail } from '~/utils';
import { SUPPORT_EMAIL } from '~/constants';
import { openInviteTeammatesModal } from '~/components/modals';
import { LabelXSmall, TitleMedium } from '~/components/typography';

import KelloLogo from '~/assets/kello-logo.svg';

const Header = forwardRef(({ trackEvent = noop, ...rest }, ref) => {
  const { user, orgUsers, isBetaMode, isDevMode, setIsDevMode, setIsBetaMode } = useAppContext();

  const theme = useMantineTheme();
  const isTabletOrMobileMedia = useIsTabletOrMobileMedia();

  const handleLogoClick = () => {
    trackEvent('attempted_to_navigate');
  };

  const handleInviteBtnClick = (e) => {
    e.preventDefault();
    trackEvent('attempted_to_invite_team_to_org', {
      section: 'profile_dropdown',
      team_count: orgUsers.length,
    });
    openInviteTeammatesModal({
      title: 'Invite your team',
      innerProps: {
        collaborators: orgUsers,
        trackEvent,
      },
    });
  };

  const handleOpenProfileMenu = () => {
    trackEvent('opened_profile_dropdown');
  };

  const handleGiveFeedBackOptionClick = (e) => {
    e.preventDefault();
    trackEvent('attempted_to_give_feedback');
    window.open(
      `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${SUPPORT_EMAIL}&su=${encodeURIComponent(
        `Kello - Product Feedback`,
      )}`,
    );
  };

  const handleLogoutOptionClick = (e) => {
    e.preventDefault();
    trackEvent('attempted_to_logout');
    removeCookie('access_token');
    trackEvent('logged_out');
    window.location.href = '/';
  };

  let userDetailsNode;

  if (user) {
    const showBetaSearchOption = !isProductionHostname(window.location.hostname);
    const showDevModeOption = isUpraisedEmail(user.email);
    userDetailsNode = (
      <Menu
        position="bottom-end"
        shadow="md"
        trigger="click"
        onOpen={handleOpenProfileMenu}
        disabled={!isTabletOrMobileMedia}
      >
        <Menu.Target>
          <UserDetailsContainer>
            <Avatar
              src={user.picture}
              alt="User Avatar"
              color={theme.app.colors.TEXT_ACCENT_NORMAL}
              bg={theme.app.colors.BG_ACCENT_WEAKEST}
              size="24px"
              styles={{
                root: {
                  cursor: 'pointer',
                },
              }}
            >
              {user.name[0].toUpperCase()}
            </Avatar>
            <LabelXSmall c={theme.app.colors.TEXT_NEUTRAL_NORMAL} ml="8px">
              {user.name}
            </LabelXSmall>
          </UserDetailsContainer>
        </Menu.Target>

        <Menu.Dropdown>
          {showDevModeOption ? (
            <Menu.Item closeMenuOnClick={false}>
              <Switch
                label="Dev Mode"
                labelPosition="left"
                checked={isDevMode}
                onChange={(event) => setIsDevMode(event.currentTarget.checked)}
                styles={{
                  labelWrapper: {
                    width: '100%',
                    justifyContent: 'space-between',
                  },
                  track: {
                    background: isDevMode ? theme.app.colors.BG_BRAND_STRONGEST : 'inherit',
                    borderColor: isDevMode
                      ? theme.app.colors.BORDER_BRAND_STRONG
                      : theme.app.colors.BORDER_NEUTRAL_WEAKEST,
                  },
                }}
              />
            </Menu.Item>
          ) : null}
          {showBetaSearchOption ? (
            <Menu.Item closeMenuOnClick={false}>
              <Switch
                label="Beta Search"
                labelPosition="left"
                checked={isBetaMode}
                onChange={(event) => setIsBetaMode(event.currentTarget.checked)}
                styles={{
                  labelWrapper: {
                    width: '100%',
                    justifyContent: 'space-between',
                  },
                  track: {
                    background: isBetaMode ? theme.app.colors.BG_BRAND_STRONGEST : 'inherit',
                    borderColor: isBetaMode
                      ? theme.app.colors.BORDER_BRAND_STRONG
                      : theme.app.colors.BORDER_NEUTRAL_WEAKEST,
                  },
                }}
              />
            </Menu.Item>
          ) : null}
          <Menu.Item onClickCapture={handleInviteBtnClick}>Invite Team</Menu.Item>
          <Menu.Item closeMenuOnClick={false} onClickCapture={handleGiveFeedBackOptionClick}>
            Give Feedback
          </Menu.Item>
          <Menu.Item
            rightSection={<IconLogout size={20} stroke={1.5} color={theme.app.colors.ICON_NEUTRAL_WEAKEST} />}
            onClickCapture={handleLogoutOptionClick}
          >
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );
  }

  return (
    <Root component="header" ref={ref} {...rest}>
      <Content>
        <BrandDetailsContainer component={NavLink} to="/app" display="inline-flex" onClick={handleLogoClick}>
          <LogoWrapper>
            <KelloLogo />
          </LogoWrapper>
          <TitleMedium ml="12px">kello</TitleMedium>
        </BrandDetailsContainer>
        {userDetailsNode}
      </Content>
    </Root>
  );
});

export default Header;

export const Root = styled(Box)`
  height: 76px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: ${({ theme }) => theme.app.colors.BG_SURFACE};
`;

const Content = styled(Flex)`
  height: 100%;
  margin: auto;
  max-width: 1272px; // 1272 = 1224 content + 24 padding on left and right
  padding: 24px;
  align-items: center;
  justify-content: space-between;
`;

export const BrandDetailsContainer = styled(Flex)`
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
  transform: all 300ms ease-in-out;
`;

export const LogoWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;

  > svg {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
  }
`;

export const UserDetailsContainer = styled(Flex)`
  background: ${({ theme }) => theme.app.colors.BG_NEUTRAL_WEAKEST};
  align-items: center;
  border-radius: 32px;
  padding: 8px;
  padding-right: 12px;
`;
