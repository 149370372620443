import { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Checkbox, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { debounce } from 'lodash-es';

import { Button, Input } from '~/components/atoms';
import { LabelSmall, TitleXSmall } from '~/components/typography';
import { useRequestStates } from '~/hooks';
import { jobApi } from '~/api';
import { extractResponseError } from '~/api/utils';

import CrossIconComponent from '~/assets/icons/cross.svg';
import MagnifyingGlassIconComponent from '~/assets/icons/magnifying-glass.svg';

const PinJobsModal = ({ innerProps, context, id }) => {
  const theme = useMantineTheme();
  const { onSaveChanges, requestStates = {}, pinnedJobs, allJobs } = innerProps;

  const [searchTerm, setSearchTerm] = useState('');
  const [initialSelection, setInitialSelection] = useState([]);
  const initialPinnedJobs = pinnedJobs.map((job) => job.id);

  const form = useForm({
    initialValues: {
      selectedJobs: initialPinnedJobs,
    },
  });

  const [searchJobsRequestStates, searchJobsRequestHandlers] = useRequestStates();

  const handleSearchJobs = async (query) => {
    try {
      searchJobsRequestHandlers.pending();
      const resp = await jobApi.fetchAllJobs({ query });
      searchJobsRequestHandlers.fulfilled(resp.result);
    } catch (error) {
      const { errorMessage } = extractResponseError(error);
      searchJobsRequestHandlers.rejected(errorMessage);
    }
  };

  const debouncedSearchJobs = useCallback(debounce(handleSearchJobs, 500), []);

  useEffect(() => {
    if (searchTerm) {
      debouncedSearchJobs(searchTerm);
    }
  }, [searchTerm, debouncedSearchJobs]);

  // Helper function to check if a job is pinned
  const isJobPinned = (jobId) => pinnedJobs.some((pinnedJob) => pinnedJob.id === jobId);

  // Transform allJobs into a structure grouped by department
  const jobsByDepartment = Object.entries(allJobs).reduce((acc, [department, jobs]) => {
    acc[department] = jobs.filter((job) => !isJobPinned(job.id));
    return acc;
  }, {});

  useEffect(() => {
    setInitialSelection(form.values.selectedJobs);
  }, []);

  // Update filteredJobs to work with jobsByDepartment
  let jobsToDisplay = jobsByDepartment;
  if (searchTerm.length > 0 && searchJobsRequestStates.fulfilled) {
    jobsToDisplay = searchJobsRequestStates.data;
  }
  const filteredJobs = Object.entries(jobsToDisplay).reduce((acc, [department, jobs]) => {
    const filtered = jobs.filter((job) => !isJobPinned(job.id));
    if (filtered.length > 0) {
      acc[department] = filtered;
    }
    return acc;
  }, {});

  const departments = Object.keys(filteredJobs);

  const handleCloseModal = () => {
    context.closeModal(id);
  };

  const handleOnSubmit = (values) => {
    const selectedJobs = values.selectedJobs;
    const removedJobs = initialPinnedJobs.filter((jobId) => !selectedJobs.includes(jobId));
    const addedJobs = selectedJobs.filter((jobId) => !initialPinnedJobs.includes(jobId));

    onSaveChanges({ selectedJobs: addedJobs, removedJobs });
    context.closeModal(id);
  };

  const allJobsCount = Object.values(jobsByDepartment).flat().length + pinnedJobs.length;
  const allSelected = form.values.selectedJobs.length === allJobsCount;

  const handleSelectAll = () => {
    const allJobIds = [
      ...pinnedJobs.map((job) => job.id),
      ...Object.values(jobsByDepartment)
        .flat()
        .map((job) => job.id),
    ];
    form.setFieldValue('selectedJobs', allJobIds);
  };

  const handleDeselectAll = () => {
    form.setFieldValue('selectedJobs', []);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const toggleJobSelection = (jobId) => {
    const currentSelection = form.values.selectedJobs;
    const isSelected = currentSelection.includes(jobId);

    const newSelection = isSelected ? currentSelection.filter((id) => id !== jobId) : [...currentSelection, jobId];

    form.setFieldValue('selectedJobs', newSelection);
  };

  const hasChanges = JSON.stringify(initialSelection) !== JSON.stringify(form.values.selectedJobs);

  let errorNode;

  if (requestStates.rejected) {
    const errorMessage = requestStates.error?.errorMessage || 'Failed to pin jobs.';
    errorNode = (
      <ParaXSmallStrong c={theme.app.colors.TEXT_NEGATIVE_STRONG} ta="center">
        {errorMessage}
      </ParaXSmallStrong>
    );
  }

  return (
    <Box component="form" onSubmit={form.onSubmit(handleOnSubmit)}>
      <Header>
        <Flex align="center">
          <TitleXSmall>Select Jobs to be Pinned</TitleXSmall>
        </Flex>
        <CrossIcon component={CrossIconComponent} onClick={handleCloseModal} />
      </Header>
      <Box p="24px" w="100%" mb="72px">
        <InputComponent>
          <MagnifyingGlassIcon component={MagnifyingGlassIconComponent} />
          <Input
            styles={{
              input: {
                height: '40px',
                width: '100%',
                paddingLeft: '44px',
                placeholder: {
                  color: theme.app.colors.TEXT_NEUTRAL_WEAK,
                },
              },
            }}
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputComponent>
        <Box mt="24px">
          {pinnedJobs.length > 0 && (
            <Box mb="24px">
              <Flex align="center" justify="center" mb="8px">
                <LabelSmall
                  c={theme.app.colors.TEXT_NEUTRAL_WEAK}
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  Pinned Jobs
                </LabelSmall>
                <LabelSmall c={theme.app.colors.TEXT_NEUTRAL_WEAK}>({pinnedJobs.length})</LabelSmall>
                <Line />
              </Flex>
              <Box mt="8px">
                {pinnedJobs.map((job) => (
                  <Checkbox
                    key={job.id}
                    label={job.title}
                    value={job.id}
                    checked={form.values.selectedJobs.includes(job.id)}
                    onChange={() => toggleJobSelection(job.id)}
                    color={theme.app.colors.BG_ACCENT_NORMAL}
                    pt="6px"
                    pb="6px"
                  />
                ))}
              </Box>
            </Box>
          )}
          {departments.map((department) => (
            <Box key={department} mb="24px">
              <Flex align="center" justify="center" mb="8px">
                <LabelSmall c={theme.app.colors.TEXT_NEUTRAL_WEAK}>{capitalizeFirstLetter(department)}</LabelSmall>
                <LabelSmall c={theme.app.colors.TEXT_NEUTRAL_WEAK}>({filteredJobs[department].length})</LabelSmall>
                <Line />
              </Flex>
              <Box mt="8px">
                {filteredJobs[department].map((job) => (
                  <Checkbox
                    key={job.id}
                    label={job.title}
                    value={job.id}
                    checked={form.values.selectedJobs.includes(job.id)}
                    onChange={() => toggleJobSelection(job.id)}
                    color={theme.app.colors.BG_ACCENT_NORMAL}
                    pt="6px"
                    pb="6px"
                  />
                ))}
              </Box>
            </Box>
          ))}
        </Box>
        <Box mt="24px">{errorNode}</Box>
      </Box>
      <Footer>
        <Button
          size={Button.SIZES.SMALL}
          color={Button.COLORS.NEUTRAL}
          variant={Button.VARIANTS.OUTLINED}
          onClick={allSelected ? handleDeselectAll : handleSelectAll}
          text={allSelected ? 'Deselect All' : 'Select All'}
        />
        <Button
          size={Button.SIZES.SMALL}
          ml="12px"
          type="submit"
          loading={requestStates.pending}
          text="Save Changes"
          disabled={!hasChanges}
        />
      </Footer>
    </Box>
  );
};

const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.app.colors.BORDER_NEUTRAL_WEAKEST};
`;

const CrossIcon = styled(Box)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.app.colors.ICON_NEUTRAL_WEAK};
`;

const InputComponent = styled(Box)`
  position: relative;
  width: 100%;
`;

const MagnifyingGlassIcon = styled(Box)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  z-index: 1;
  color: ${({ theme }) => theme.app.colors.ICON_NEUTRAL_WEAK};
`;

const Line = styled(Box)`
  width: 100%;
  height: 1px;
  margin-left: 16px;
  background-color: ${({ theme }) => theme.app.colors.BORDER_NEUTRAL_WEAKEST};
`;

export const Footer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.app.colors.BG_SURFACE};
  z-index: 1;
  border-top: 1px solid ${({ theme }) => theme.app.colors.BORDER_NEUTRAL_WEAKEST};
`;

export default PinJobsModal;
