import styled from '@emotion/styled';
import { Box, Flex } from '@mantine/core';

import { ParaMediumStrong, TitleXSmall } from '~/components/typography';

import CloseIconComponent from '~/assets/icons/close.svg';

const VideoModal = ({ id, innerProps, context }) => {
  const { title, videoUrl, autoPlay = true } = innerProps;

  let videoSrcUrl = videoUrl;

  if (autoPlay && videoSrcUrl) {
    const urlWithParams = new URL(videoSrcUrl);
    urlWithParams.searchParams.set('autoplay', '1');
    videoSrcUrl = urlWithParams.toString();
  }

  const handleCloseModal = () => {
    context.closeModal(id);
  };

  return (
    <Box>
      <Header>
        <Flex align="center">
          <TitleXSmall>{title}</TitleXSmall>
        </Flex>
        <CloseIcon component={CloseIconComponent} onClick={handleCloseModal} />
      </Header>

      {videoSrcUrl ? (
        <IframeContainer>
          <iframe
            width="560"
            height="315"
            src={videoSrcUrl}
            title={title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder={0}
          />
        </IframeContainer>
      ) : (
        <Flex justify="center" p="48px">
          <ParaMediumStrong>Video is not available</ParaMediumStrong>
        </Flex>
      )}
    </Box>
  );
};

export default VideoModal;

const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.app.colors.BORDER_NEUTRAL_WEAKEST};
`;

export const IframeContainer = styled(Box)`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0 0 8px 8px;
  }
`;

const CloseIcon = styled(Box)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.app.colors.ICON_NEUTRAL_WEAK};
`;
