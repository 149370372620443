import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import App from './App';
import { initSentry } from './lib/sentry';
import { initLiveChat } from './utils/liveChat';

const history = createBrowserHistory();

initSentry({ history });
initLiveChat();

const container = document.getElementById('app');
const root = createRoot(container);

root.render(
  <HistoryRouter history={history}>
    <App history={history} />
  </HistoryRouter>,
);

if (module.hot) {
  module.hot.accept();
}
