import styled from '@emotion/styled';
import { Menu as MantineMenu } from '@mantine/core';
import { noop } from 'lodash-es';

export const MenuItem = styled(MantineMenu.Item)`
  font-size: 14px;
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_NORMAL};
`;

const Menu = ({ children, onOpen = noop, ...props }) => {
  const { styles = {}, ...rest } = props;
  const { dropdown: dropdownStyles = {}, item: itemStyles = {}, ...restStyles } = styles || {};
  return (
    <MantineMenu
      position="bottom-start"
      shadow="md"
      trigger="click"
      closeOnClickOutside
      width="200px"
      onOpen={onOpen}
      styles={{
        dropdown: {
          border: 'none',
          padding: '8px',
          borderRadius: '12px',
          boxShadow:
            '0px 217px 61px 0px rgba(0, 0, 0, 0.00), 0px 139px 56px 0px rgba(0, 0, 0, 0.00), 0px 78px 47px 0px rgba(0, 0, 0, 0.02), 0px 35px 35px 0px rgba(0, 0, 0, 0.03), 0px 9px 19px 0px rgba(0, 0, 0, 0.03)',
          ...dropdownStyles,
        },
        item: {
          padding: '12px',
          ...itemStyles,
        },
        ...restStyles,
      }}
      {...rest}
    >
      {children}
    </MantineMenu>
  );
};

Menu.Target = MantineMenu.Target;
Menu.Dropdown = MantineMenu.Dropdown;
Menu.Item = MenuItem;

export default Menu;
