import { Group, Loader } from '@mantine/core';

const PageContentLoader = ({ ...restProps }) => {
  return (
    <Group justify="center" py="lg" {...restProps}>
      <Loader type="bars" size="xs" color="dark" />
    </Group>
  );
};

export default PageContentLoader;
