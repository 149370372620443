export const generateOrgAttributes = ({ org, user }) => {
  if (!org || !user) return null;

  const orgStatus = user.isOnboardingAllowed ? 'WHITELISTED' : 'NOT_WHITELISTED';

  return {
    id: org.id,
    name: org.name,
    domain: org.domain,
    industry: org.industry,
    size: org.size,
    status: orgStatus,
    created_at: org.createdAt,
    created_at_ist: new Date(org.createdAt),
  };
};

export const generateJobAttributes = (job) => {
  if (!job) return null;

  return {
    id: job.id,
    slug: job.slug,
    title: job.title,
    applications_count: job.totalApplications,
    status: job.status,
    created_at: job.createdAt,
    created_at_ist: new Date(job.createdAt),
  };
};

export const generateJobApplicationAttributes = (jobApplication) => {
  if (!jobApplication) return null;

  return {
    id: jobApplication.id,
    created_at: jobApplication.createdAt,
    created_at_ist: new Date(jobApplication.createdAt),
    sourced_at: jobApplication.sourcedAt,
    sourced_at_ist: new Date(jobApplication.sourcedAt),
    stage: jobApplication.stage,
    sub_stage: jobApplication.subStage,
    email: jobApplication.primaryEmail,
    similarity: jobApplication.similarity,
  };
};

export const generateCandidateAttributes = (candidate) => {
  if (!candidate) return null;

  return {
    id: candidate.id,
    sourced_at: candidate.sourcedAt,
    similarity: candidate.similarity,
    resume_url: candidate.resumeUrl,
    sourced_at_ist: new Date(candidate.sourcedAt),
    sourced_by: candidate.sourcedBy,
    sourced_from: candidate.sourcedFrom,
    created_at: candidate.createdAt,
    created_at_ist: new Date(candidate.createdAt),
  };
};

export const generateCollectionAttributes = (collection) => {
  if (!collection) return null;

  return {
    id: collection.id,
    name: collection.name,
    owner_user_id: collection.userId,
    candidates_count: collection.candidateCount,
    shared_count: collection.usersWithPermission.length,
    created_at: collection.createdAt,
    created_at_ist: new Date(collection.createdAt),
  };
};

export const generateSearchQueryAttributes = (searchQuery) => {
  if (!searchQuery) return null;

  return {
    id: searchQuery.id,
    is_saved: searchQuery.isSaved,
    job: { id: searchQuery.jobId },
    query_url: searchQuery.queryUrl,
    searched_on: searchQuery.savedAt,
    query: searchQuery.searchQuery,
    version: searchQuery.version,
    created_at: searchQuery.createdAt,
    created_at_ist: new Date(searchQuery.createdAt),
  };
};
