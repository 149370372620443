import { Box, Flex, useMantineTheme } from '@mantine/core';
import styled from '@emotion/styled';
import { useImmer } from 'use-immer';
import { noop } from 'lodash-es';

import { Button } from '~/components/atoms';
import { LabelSmallStrong, ParaXSmall, TitleXSmall } from '~/components/typography';
import { useAppContext } from '~/contexts/app';
import { useRequestStates } from '~/hooks';
import { extractResponseError } from '~/api/utils';
import { jobApi } from '~/api';
import { notifyError, notifySuccess } from '~/utils/notifications';

import SettingsIconComponent from '~/assets/icons/bag.svg';
import CrossIconComponent from '~/assets/icons/cross.svg';

import StageSelector from './components/StageSelector';

const ConfigureJobStageModal = ({ innerProps, context, id }) => {
  const theme = useMantineTheme();
  const { candidateArchiveReasons, jobApplicationStages, setDefaultJobConfiguration, defaultJobConfiguration } =
    useAppContext();

  const { jobId, onSaveChanges = noop, trackEvent } = innerProps;

  const defaultShortlistStage = jobApplicationStages.find(
    (stage) => stage.id === defaultJobConfiguration.shortlistStageId,
  );

  const defaultArchiveReason = candidateArchiveReasons.find(
    (reason) => reason.id === defaultJobConfiguration.archiveReasonId,
  );

  const [saveJobConfigRequestStates, saveJobConfigRequestHandlers] = useRequestStates();

  const isAlreadyConfigured = !!defaultArchiveReason & !!defaultShortlistStage;

  const [modalState, setModalState] = useImmer({
    selectedArchiveReason: defaultArchiveReason,
    selectedStage: defaultShortlistStage,
  });

  const handleSaveChanges = async () => {
    try {
      trackEvent('attempted_to_configure_job_defaults', {
        shortlist_stage: modalState.selectedStage?.stage,
        archive_reason: modalState.selectedArchiveReason?.reason,
      });
      saveJobConfigRequestHandlers.pending();
      const payload = {
        defaultShortlistStageId: modalState.selectedStage?.id,
        defaultArchiveReasonId: modalState.selectedArchiveReason?.id,
      };
      let resp;
      if (isAlreadyConfigured) {
        resp = await jobApi.updateJobConfig({ jobId, payload });
      } else {
        resp = await jobApi.saveJobConfig({ jobId, payload });
      }
      saveJobConfigRequestHandlers.fulfilled(resp.result);
      setDefaultJobConfiguration((draft) => {
        draft.shortlistStageId = payload.defaultShortlistStageId;
        draft.archiveReasonId = payload.defaultArchiveReasonId;
      });
      handleCloseModal();
      onSaveChanges();
      notifySuccess({
        message: 'Successfully saved default configuration.',
      });
    } catch (error) {
      const { errorMessage } = extractResponseError(error);
      notifyError({ message: errorMessage });
      saveJobConfigRequestHandlers.rejected(errorMessage);
    }
  };

  const handleCloseModal = () => {
    context.closeModal(id);
  };

  const handleSelectStage = (value) => {
    setModalState((draft) => {
      draft.selectedStage = value;
    });
  };

  const handleSelectArchiveReason = (value) => {
    setModalState((draft) => {
      draft.selectedArchiveReason = value;
    });
  };

  return (
    <Box>
      <Header>
        <Flex align="center">
          <SettingsIcon component={SettingsIconComponent} />
          <TitleXSmall c={theme.app.colors.TEXT_NEUTRAL_STRONG}>Configure Job</TitleXSmall>
        </Flex>
        <CrossIcon component={CrossIconComponent} onClick={handleCloseModal} />
      </Header>

      <Box p="24px" mb="72px">
        <Box pb="24px" style={{ borderBottom: `1px solid ${theme.app.colors.BORDER_NEUTRAL_WEAKEST}` }}>
          <LabelSmallStrong mb="4px" c={theme.app.colors.TEXT_NEUTRAL_STRONG}>
            Default ATS stage for shortlisting candidates.
          </LabelSmallStrong>
          <ParaXSmall mb="16px" c={theme.app.colors.TEXT_NEUTRAL_NORMAL}>
            You can still choose other stages while shortlisting
          </ParaXSmall>
          <StageSelector
            style={{ zIndex: 1 }}
            value={modalState.selectedStage}
            onChange={handleSelectStage}
            data={jobApplicationStages}
          />
        </Box>

        <Box pt="24px" pb="24px">
          <LabelSmallStrong mb="4px" c={theme.app.colors.TEXT_NEUTRAL_STRONG}>
            Default archive reason for archiving candidates.
          </LabelSmallStrong>
          <ParaXSmall mb="16px" c={theme.app.colors.TEXT_NEUTRAL_NORMAL}>
            This acts as the default reason, you can still choose other archive reasons from the dropdown.
          </ParaXSmall>
          <StageSelector
            style={{ zIndex: 1 }}
            value={modalState.selectedArchiveReason}
            onChange={handleSelectArchiveReason}
            data={candidateArchiveReasons}
          />
        </Box>
      </Box>

      <Footer>
        <Button
          size={Button.SIZES.SMALL}
          onClick={handleSaveChanges}
          text={isAlreadyConfigured ? 'Update Changes' : 'Save Changes'}
          loading={saveJobConfigRequestStates.pending}
        />
      </Footer>
    </Box>
  );
};

const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.app.colors.BORDER_NEUTRAL_WEAKEST};
`;

const SettingsIcon = styled(Box)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 12px;
  color: ${({ theme }) => theme.app.colors.ICON_NEUTRAL_STRONG};
`;

const CrossIcon = styled(Box)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.app.colors.ICON_NEUTRAL_WEAK};
`;

const Footer = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 24px;
  background-color: ${({ theme }) => theme.app.colors.BG_SURFACE};
  z-index: 1;
  border-top: 1px solid ${({ theme }) => theme.app.colors.BORDER_NEUTRAL_WEAKEST};
  border-radius: 0px 0px 16px 16px;
`;

export default ConfigureJobStageModal;
