import queryString from 'query-string';

import httpClient from './client';

export const fetchCollections = ({ pageNumber = 1, pageSize = 20 } = {}, options = {}) => {
  const query = queryString.stringify({ pageNumber, pageSize });

  return httpClient.get(`/api/v1/collection?` + query, options);
};

export const fetchCollectionWithCandidates = ({ collectionId, pageNumber = 1, pageSize = 20 }, options = {}) => {
  const query = queryString.stringify({ pageNumber, pageSize });

  return httpClient.get(`/api/v1/collection/${collectionId}?` + query, options);
};

export const createCollection = ({ name, description = '' }, options = {}) => {
  return httpClient.post('/api/v1/collection', { name, description }, options);
};

export const addCandidatesToCollection = ({ collectionId, payload }, options = {}) => {
  return httpClient.patch(`/api/v1/collection/${collectionId}/candidate`, payload, options);
};

export const removeCandidatesFromCollection = ({ collectionId, candidateId }, options = {}) => {
  return httpClient.delete(`/api/v1/collection/${collectionId}/candidate/${candidateId}`, options);
};

export const updateCollectionInfo = ({ collectionId, payload }, options = {}) => {
  return httpClient.patch(`/api/v1/collection/${collectionId}/info`, payload, options);
};

export const deleteCollection = ({ collectionId }, options = {}) => {
  return httpClient.delete(`/api/v1/collection/${collectionId}`, options);
};

export const joinCollectionWithInviteLink = ({ collectionId }, options = {}) => {
  return httpClient.patch(`/api/v1/collection/${collectionId}/join`, options);
};
