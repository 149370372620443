import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Text } from '@mantine/core';

import theme from '~/styles/theme';

const mediaQuerySmallScreen = '@media (max-width: 599px)';

export const displayLargeCss = css`
  font-size: 64px;
  line-height: 72px;
  letter-spacing: -0.02em;
  font-weight: ${theme.app.fontWeights.strong};

  ${mediaQuerySmallScreen} {
    font-size: 40px;
    line-height: 44px;
  }
`;

export const DisplayLarge = styled(Text)`
  ${displayLargeCss};
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_STRONG};
`;

export const displayMediumCss = css`
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.02em;
  font-weight: ${theme.app.fontWeights.strong};

  ${mediaQuerySmallScreen} {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.015em;
  }
`;

export const DisplayMedium = styled(Text)`
  ${displayMediumCss};
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_STRONG};
`;

export const titleXXLargeCss = css`
  font-size: 64px;
  line-height: 72px;
  letter-spacing: -0.02em;
  font-weight: ${theme.app.fontWeights.medium};

  ${mediaQuerySmallScreen} {
    font-size: 40px;
    line-height: 44px;
  }
`;

export const TitleXXLarge = styled(Text)`
  ${titleXXLargeCss};
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_STRONG};
`;

export const titleXLargeCss = css`
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.02em;
  font-weight: ${theme.app.fontWeights.medium};

  ${mediaQuerySmallScreen} {
    font-size: 32px;
    line-height: 36px;
  }
`;

export const TitleXLarge = styled(Text)`
  ${titleXLargeCss};
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_STRONG};
`;

export const titleLargeCss = css`
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -0.02em;
  font-weight: ${theme.app.fontWeights.medium};

  ${mediaQuerySmallScreen} {
    font-size: 26px;
    line-height: 30px;
    letter-spacing: -0.015em;
  }
`;

export const TitleLarge = styled(Text)`
  ${titleLargeCss};
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_STRONG};
`;

export const titleMediumCss = css`
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.015em;
  font-weight: ${theme.app.fontWeights.medium};

  ${mediaQuerySmallScreen} {
    font-size: 22px;
    line-height: 26px;
  }
`;

export const TitleMedium = styled(Text)`
  ${titleMediumCss};
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_STRONG};
`;

export const titleSmallCss = css`
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.015em;
  font-weight: ${theme.app.fontWeights.medium};

  ${mediaQuerySmallScreen} {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const TitleSmall = styled(Text)`
  ${titleSmallCss};
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_STRONG};
`;

export const titleXSmallCss = css`
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.015em;
  font-weight: ${theme.app.fontWeights.medium};

  ${mediaQuerySmallScreen} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const TitleXSmall = styled(Text)`
  ${titleXSmallCss};
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_STRONG};
`;

export const titleXXSmallCss = css`
  font-size: 14px;
  line-height: 20px;
  /* letter-spacing: 0.05em; */
  font-weight: ${theme.app.fontWeights.medium};

  ${mediaQuerySmallScreen} {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const TitleXXSmall = styled(Text)`
  ${titleXXSmallCss};
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_STRONG};
`;

export const paraLargeCss = css`
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.015em;
  font-weight: ${theme.app.fontWeights.regular};

  ${mediaQuerySmallScreen} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ParaLarge = styled(Text)`
  ${paraLargeCss};
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_NORMAL};
`;

export const ParaLargeStrong = styled(ParaLarge)`
  font-weight: ${theme.app.fontWeights.medium};
`;

export const paraMediumCss = css`
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.015em;
  font-weight: ${theme.app.fontWeights.regular};

  ${mediaQuerySmallScreen} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const ParaMedium = styled(Text)`
  ${paraMediumCss};
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_NORMAL};
`;

export const ParaMediumStrong = styled(ParaMedium)`
  font-weight: ${theme.app.fontWeights.strong};
`;

export const paraSmallCss = css`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.015em;
  font-weight: ${theme.app.fontWeights.regular};

  ${mediaQuerySmallScreen} {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const ParaSmall = styled(Text)`
  ${paraSmallCss};
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_NORMAL};
`;

export const ParaSmallStrong = styled(ParaSmall)`
  font-weight: ${theme.app.fontWeights.strong};
`;

export const paraXSmallCss = css`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.015em;
  font-weight: ${theme.app.fontWeights.regular};

  ${mediaQuerySmallScreen} {
    font-size: 10px;
    line-height: 16px;
  }
`;

export const ParaXSmall = styled(Text)`
  ${paraXSmallCss};
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_NORMAL};
`;

export const ParaXSmallStrong = styled(ParaXSmall)`
  font-weight: ${theme.app.fontWeights.strong};
`;

export const labelLargeCss = css`
  font-size: 18px;
  font-weight: ${theme.app.fontWeights.regular};

  ${mediaQuerySmallScreen} {
    font-size: 16px;
  }
`;

export const LabelLarge = styled(Text)`
  ${labelLargeCss};
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_NORMAL};
`;

export const LabelLargeStrong = styled(LabelLarge)`
  font-weight: ${theme.app.fontWeights.strong};
`;

export const labelMediumCss = css`
  font-size: 16px;
  font-weight: ${theme.app.fontWeights.regular};

  ${mediaQuerySmallScreen} {
    font-size: 14px;
  }
`;

export const LabelMedium = styled(Text)`
  ${labelMediumCss};
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_NORMAL};
`;

export const LabelMediumStrong = styled(LabelMedium)`
  font-weight: ${theme.app.fontWeights.strong};
`;

export const labelSmallCss = css`
  font-size: 14px;
  font-weight: ${theme.app.fontWeights.regular};

  ${mediaQuerySmallScreen} {
    font-size: 12px;
  }
`;

export const LabelSmall = styled(Text)`
  ${labelSmallCss};
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_NORMAL};
`;

export const LabelSmallStrong = styled(LabelSmall)`
  font-weight: ${theme.app.fontWeights.strong};
`;

export const labelXSmallCss = css`
  font-size: 12px;
  font-weight: ${theme.app.fontWeights.regular};

  ${mediaQuerySmallScreen} {
    font-size: 10px;
  }
`;

export const LabelXSmall = styled(Text)`
  ${labelXSmallCss};
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_NORMAL};
`;

export const LabelXSmallStrong = styled(LabelXSmall)`
  font-weight: ${theme.app.fontWeights.strong};
`;

export const labelXXSmallCss = css`
  font-size: 10px;
  font-weight: ${theme.app.fontWeights.regular};

  ${mediaQuerySmallScreen} {
    font-size: 8px;
  }
`;

export const LabelXXSmall = styled(Text)`
  ${labelXXSmallCss};
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_NORMAL};
`;

export const LabelXXSmallStrong = styled(LabelXSmall)`
  font-weight: ${theme.app.fontWeights.strong};
`;
