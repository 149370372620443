// eslint-disable-next-line no-unused-vars
import { Loader, LoadingOverlay, LoadingOverlayProps } from '@mantine/core';
// eslint-disable-next-line no-unused-vars
import { ForwardRefExoticComponent, RefAttributes } from 'react';

/**
 * Renders a loader with white overlay.
 * The component `position` is `absolute` by default and it takes the parent container dimensions.
 * @param {ForwardRefExoticComponent<LoadingOverlayProps & RefAttributes<HTMLDivElement>>} props LoadingOverlay props
 */
const LoaderWithOverlay = ({ loaderProps, ...rest }) => (
  <LoadingOverlay
    visible
    transitionProps={{
      duration: 500,
    }}
    loaderProps={{
      children: <Loader type="bars" {...loaderProps} />,
    }}
    {...rest}
  />
);

export default LoaderWithOverlay;
