import { Box, Flex, useMantineTheme } from '@mantine/core';

import { Button } from '~/components/atoms';
import { ParaMedium, ParaXSmallStrong } from '~/components/typography';

// TODO: Pass props to customize button
const ConfirmationModal = ({ innerProps, context, id }) => {
  const theme = useMantineTheme();
  const { message = '', ctaBtnLabel, requestStates, onConfirm } = innerProps;

  const handleConfirmBtnClick = () => {
    onConfirm();
    context.closeModal(id);
  };

  let errorNode;

  if (requestStates?.rejected) {
    const errorMessage = requestStates.error?.errorMessage || `Something went wrong. Please try again after sometime.`;
    errorNode = (
      <ParaXSmallStrong c={theme.app.colors.TEXT_NEGATIVE_NORMAL} ta="center">
        {errorMessage}
      </ParaXSmallStrong>
    );
  }

  return (
    <Box>
      <Box p="24px">
        <ParaMedium>{message}</ParaMedium>
      </Box>
      <Flex
        p="24px"
        justify="space-between"
        align="center"
        style={{
          borderTop: `1px solid ${theme.app.colors.BORDER_NEUTRAL_WEAKEST}`,
        }}
      >
        {errorNode ? errorNode : <Box />}
        <Button
          size={Button.SIZES.SMALL}
          variant={Button.VARIANTS.OUTLINED}
          color={Button.COLORS.NEGATIVE}
          ml="12px"
          onClick={handleConfirmBtnClick}
          loading={requestStates?.pending}
          text={ctaBtnLabel}
        />
      </Flex>
    </Box>
  );
};

export default ConfirmationModal;
