import styled from '@emotion/styled';
import { Box, Flex, List, useMantineTheme } from '@mantine/core';
import { noop } from 'lodash-es';
import { useState, useEffect, useRef } from 'react';

import { LabelSmall, LabelXSmall } from '~/components/typography';

import ChevronDownIcon from '~/assets/icons/chevron-down.svg';
import CheckIconComponent from '~/assets/icons/check-filled.svg';

const StageSelector = ({ label, data = [], placeholder = 'Select options', value, onChange = noop, ...rest }) => {
  const theme = useMantineTheme();
  const selectRootRef = useRef(null);

  const modifiedData = data.map((item) => ({
    ...item,
    value: item.id,
    label: item.reason || item.stage,
  }));

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(modifiedData);

  const selectedItem = modifiedData?.find((item) => item.value === value?.id);

  const handleSelectOption = (optionVal) => {
    onChange(optionVal);
    setSearchTerm('');
    setFilteredOptions(modifiedData);
    handleToggleDropdown();
  };

  const handleToggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (Array.isArray(modifiedData)) {
      setFilteredOptions(
        modifiedData.filter((option) => option.value.toLowerCase().includes(searchTerm.toLowerCase())),
      );
    } else {
      setFilteredOptions([]);
    }
  }, [searchTerm, modifiedData.length]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRootRef.current && !selectRootRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box miw="180px" {...rest}>
      {label ? (
        <LabelXSmall c={theme.app.colors.TEXT_NEUTRAL_NORMAL} mb="8px">
          {label}
        </LabelXSmall>
      ) : null}
      <SelectRoot ref={selectRootRef}>
        <ValueContainer isOpen={isOpen} onClick={handleToggleDropdown}>
          <LabelSmall
            mr="auto"
            c={value ? theme.app.colors.TEXT_NEUTRAL_NORMAL : theme.app.colors.TEXT_NEUTRAL_WEAKEST}
          >
            {selectedItem ? selectedItem.label : placeholder}
          </LabelSmall>
          {isOpen ? (
            <ChevronIconWrapper component={ChevronDownIcon} style={{ transform: 'rotate(180deg)' }} />
          ) : (
            <ChevronIconWrapper component={ChevronDownIcon} />
          )}
        </ValueContainer>
        {isOpen ? (
          <DropdownContainer style={{ zIndex: 1000 }}>
            <List mah="250px" pb="4px" px="4px" listStyleType="none" style={{ overflow: 'auto' }}>
              {filteredOptions.map((option) => (
                <ListItem
                  key={option.value}
                  onClick={() => handleSelectOption(option)}
                  styles={{
                    itemWrapper: {
                      width: '100%',
                    },
                    itemLabel: {
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    },
                  }}
                >
                  <LabelSmall>{option.label}</LabelSmall>
                  {value === option.value ? <CheckIcon component={CheckIconComponent} /> : null}
                </ListItem>
              ))}
            </List>
          </DropdownContainer>
        ) : null}
      </SelectRoot>
    </Box>
  );
};

export default StageSelector;

const SelectRoot = styled(Flex)`
  width: 100%;
  height: 48px;
  position: relative;
`;

const ValueContainer = styled(Flex)`
  width: 100%;
  height: 48px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.app.colors.BG_NEUTRAL_WEAKER};
  border: 1px solid
    ${({ theme, isOpen }) => (isOpen ? theme.app.colors.BORDER_ACCENT_NORMAL : theme.app.colors.BORDER_NEUTRAL_WEAKEST)};
  padding: 16px;
  cursor: pointer;
`;

const ChevronIconWrapper = styled(Box)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.app.colors.ICON_NEUTRAL_WEAKEST};
  transition: transform 300ms ease-in-out;
`;

const DropdownContainer = styled(Box)`
  width: 100%;
  margin-top: 10px;
  border-radius: 8px;
  background: ${({ theme }) => theme.app.colors.BG_SURFACE};
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1;
`;

const ListItem = styled(List.Item)`
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.app.colors.BG_NEUTRAL_WEAKEST};
  }
`;

const CheckIcon = styled(Box)`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.app.colors.ICON_NEUTRAL_STRONG};
`;
