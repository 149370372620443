import { useEffect } from 'react';
import { isFunction } from 'lodash-es';
import { useSearchParams } from 'react-router-dom';

/**
 * Manage query params in bulk. The caller should handle the serialisation/deserialisation
 * @param {Record<string, string>|Function} initObjOrCb
 */
export default function useQueryParams(initObjOrCb) {
  const [params, setParams] = useSearchParams();
  const queryManager = {
    get value() {
      return params;
    },

    set(objOrCb, opts = { replace: false }) {
      let newState;
      if (isFunction(objOrCb)) {
        newState = objOrCb(params);
      } else {
        newState = objOrCb;
      }

      for (const key in newState) {
        params.set(key, newState[key]);
      }
      setParams(params, opts);
    },

    append(objOrCb, opts = { replace: false }) {
      let newState;
      if (isFunction(objOrCb)) {
        newState = objOrCb(params);
      } else {
        newState = objOrCb;
      }

      for (const key in newState) {
        params.append(key, newState[key]);
      }
      setParams(params, opts);
    },

    remove(key, opts = { replace: false }) {
      params.delete(key);
      setParams(params, opts);
    },

    replace(newParams, opts = { replace: false }) {
      setParams(newParams, opts);
    },
  };

  useEffect(() => {
    let initialValue;
    if (isFunction(initObjOrCb)) {
      initialValue = initObjOrCb(params);
    } else {
      initialValue = initObjOrCb;
    }
    if (initialValue) {
      queryManager.set(initialValue);
    }
  }, []);

  return queryManager;
}
