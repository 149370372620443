import { forwardRef, useRef } from 'react';
import styled from '@emotion/styled';
import { Box, UnstyledButton, useMantineTheme, STYlE_PROPS_DATA, Loader, Flex } from '@mantine/core';
import { css } from '@emotion/react';
import isPropValid from '@emotion/is-prop-valid';
import { noop } from 'lodash-es';

import ChevronDownIcon from '~/assets/icons/chevron-down.svg';

// TODO: Refractor entire component
const VARIANTS = {
  FILLED: 'filled',
  OUTLINED: 'outlined',
  TRANSPARENT: 'transparent',
};

const SIZES = {
  X_SMALL: 'x_small',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const COLORS = {
  BRAND: 'brand',
  ACCENT: 'accent',
  NEGATIVE: 'negative',
  NEUTRAL: 'neutral',
};

const STATES = {
  DEFAULT: 'DEFAULT',
  HOVERED: 'HOVERED',
  PRESSED: 'PRESSED',
  LOADING: 'LOADING',
  FOCUSED: 'FOCUSED',
  DISABLED: 'DISABLED',
};

const getHeight = ({ size }) => {
  switch (size) {
    case SIZES.LARGE:
      return 56;
    case SIZES.MEDIUM:
      return 48;
    case SIZES.SMALL:
      return 40;
    case SIZES.X_SMALL:
      return 32;
  }
};

const getWidth = ({ fullWidth }) => {
  return fullWidth ? '100%' : 'auto';
};

const getInlinePadding = ({ size }) => {
  switch (size) {
    case SIZES.LARGE:
      return 14;
    case SIZES.MEDIUM:
      return 12;
    case SIZES.SMALL:
      return 10;
    case SIZES.X_SMALL:
      return 8;
  }

  return 0;
};

const getColorStyles = ({ variant, color, theme }) => {
  if (variant === VARIANTS.FILLED) {
    switch (color) {
      case COLORS.NEUTRAL:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_NEUTRAL_NORMAL,
          [STATES.HOVERED]: theme.app.colors.TEXT_NEUTRAL_NORMAL,
          [STATES.PRESSED]: theme.app.colors.TEXT_NEUTRAL_WEAK,
          [STATES.LOADING]: theme.app.colors.TEXT_NEUTRAL_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.TEXT_NEUTRAL_NORMAL,
          [STATES.DISABLED]: theme.app.colors.TEXT_NEUTRAL_WEAKEST,
        };

      default:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_INVERTED,
          [STATES.HOVERED]: theme.app.colors.TEXT_INVERTED,
          [STATES.PRESSED]: theme.app.colors.TEXT_INVERTED,
          [STATES.LOADING]: theme.app.colors.TEXT_INVERTED,
          [STATES.FOCUSED]: theme.app.colors.TEXT_INVERTED,
          [STATES.DISABLED]: theme.app.colors.TEXT_INVERTED,
        };
    }
  }

  if (variant === VARIANTS.OUTLINED) {
    switch (color) {
      case COLORS.BRAND:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_BRAND_NORMAL,
          [STATES.HOVERED]: theme.app.colors.TEXT_BRAND_WEAK,
          [STATES.PRESSED]: theme.app.colors.TEXT_BRAND_STRONG,
          [STATES.LOADING]: theme.app.colors.TEXT_BRAND_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.TEXT_BRAND_WEAK,
          [STATES.DISABLED]: theme.app.colors.TEXT_BRAND_WEAKEST,
        };

      case COLORS.ACCENT:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_ACCENT_NORMAL,
          [STATES.HOVERED]: theme.app.colors.TEXT_ACCENT_WEAK,
          [STATES.PRESSED]: theme.app.colors.TEXT_ACCENT_STRONG,
          [STATES.LOADING]: theme.app.colors.TEXT_ACCENT_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.TEXT_ACCENT_WEAK,
          [STATES.DISABLED]: theme.app.colors.TEXT_ACCENT_WEAKEST,
        };

      case COLORS.NEGATIVE:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_NEGATIVE_NORMAL,
          [STATES.HOVERED]: theme.app.colors.TEXT_NEGATIVE_WEAK,
          [STATES.PRESSED]: theme.app.colors.TEXT_NEGATIVE_STRONG,
          [STATES.LOADING]: theme.app.colors.TEXT_NEGATIVE_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.TEXT_NEGATIVE_WEAK,
          [STATES.DISABLED]: theme.app.colors.TEXT_NEGATIVE_WEAKEST,
        };

      case COLORS.NEUTRAL:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_NEUTRAL_NORMAL,
          [STATES.HOVERED]: theme.app.colors.TEXT_NEUTRAL_WEAK,
          [STATES.PRESSED]: theme.app.colors.TEXT_NEUTRAL_NORMAL,
          [STATES.LOADING]: theme.app.colors.TEXT_NEUTRAL_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.TEXT_NEUTRAL_WEAK,
          [STATES.DISABLED]: theme.app.colors.TEXT_NEUTRAL_WEAKEST,
        };
    }
  }

  if (variant === VARIANTS.TRANSPARENT) {
    switch (color) {
      case COLORS.BRAND:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_BRAND_NORMAL,
          [STATES.HOVERED]: theme.app.colors.TEXT_BRAND_NORMAL,
          [STATES.PRESSED]: theme.app.colors.TEXT_BRAND_STRONG,
          [STATES.LOADING]: theme.app.colors.TEXT_BRAND_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.TEXT_BRAND_NORMAL,
          [STATES.DISABLED]: theme.app.colors.TEXT_BRAND_WEAKEST,
        };

      case COLORS.ACCENT:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_ACCENT_NORMAL,
          [STATES.HOVERED]: theme.app.colors.TEXT_ACCENT_NORMAL,
          [STATES.PRESSED]: theme.app.colors.TEXT_ACCENT_STRONG,
          [STATES.LOADING]: theme.app.colors.TEXT_ACCENT_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.TEXT_ACCENT_NORMAL,
          [STATES.DISABLED]: theme.app.colors.TEXT_ACCENT_WEAKEST,
        };

      case COLORS.NEGATIVE:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_NEGATIVE_NORMAL,
          [STATES.HOVERED]: theme.app.colors.TEXT_NEGATIVE_NORMAL,
          [STATES.PRESSED]: theme.app.colors.TEXT_NEGATIVE_STRONG,
          [STATES.LOADING]: theme.app.colors.TEXT_NEGATIVE_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.TEXT_NEGATIVE_NORMAL,
          [STATES.DISABLED]: theme.app.colors.TEXT_NEGATIVE_WEAKEST,
        };

      case COLORS.NEUTRAL:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_NEUTRAL_NORMAL,
          [STATES.HOVERED]: theme.app.colors.TEXT_NEUTRAL_NORMAL,
          [STATES.PRESSED]: theme.app.colors.TEXT_NEUTRAL_STRONG,
          [STATES.LOADING]: theme.app.colors.TEXT_NEUTRAL_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.TEXT_NEUTRAL_NORMAL,
          [STATES.DISABLED]: theme.app.colors.TEXT_NEUTRAL_WEAKEST,
        };
    }
  }
};

//TODO: Recheck colors
const getBackgroundColorStyles = ({ variant, color, theme }) => {
  if (variant === VARIANTS.OUTLINED) {
    return {
      [STATES.DEFAULT]: 'transparent',
      [STATES.HOVERED]: 'transparent',
      [STATES.PRESSED]: 'transparent',
      [STATES.LOADING]: 'transparent',
      [STATES.FOCUSED]: 'transparent',
      [STATES.DISABLED]: 'transparent',
    };
  }

  if (variant === VARIANTS.TRANSPARENT) {
    switch (color) {
      case COLORS.BRAND:
        return {
          [STATES.DEFAULT]: 'transparent',
          [STATES.HOVERED]: theme.app.colors.BG_BRAND_WEAKEST,
          [STATES.PRESSED]: theme.app.colors.BG_BRAND_WEAKER,
          [STATES.LOADING]: 'transparent',
          [STATES.FOCUSED]: theme.app.colors.BG_BRAND_WEAKEST,
          [STATES.DISABLED]: 'transparent',
        };
      case COLORS.ACCENT:
        return {
          [STATES.DEFAULT]: 'transparent',
          [STATES.HOVERED]: theme.app.colors.BG_ACCENT_WEAKEST,
          [STATES.PRESSED]: theme.app.colors.BG_ACCENT_WEAKER,
          [STATES.LOADING]: 'transparent',
          [STATES.FOCUSED]: theme.app.colors.BG_ACCENT_WEAKEST,
          [STATES.DISABLED]: 'transparent',
        };
      case COLORS.NEGATIVE:
        return {
          [STATES.DEFAULT]: 'transparent',
          [STATES.HOVERED]: theme.app.colors.BG_NEGATIVE_WEAKEST,
          [STATES.PRESSED]: theme.app.colors.BG_NEGATIVE_WEAKER,
          [STATES.LOADING]: 'transparent',
          [STATES.FOCUSED]: theme.app.colors.BG_NEGATIVE_WEAKEST,
          [STATES.DISABLED]: 'transparent',
        };
      case COLORS.NEUTRAL:
        return {
          [STATES.DEFAULT]: 'transparent',
          [STATES.HOVERED]: theme.app.colors.BG_NEUTRAL_WEAKEST,
          [STATES.PRESSED]: theme.app.colors.BG_NEUTRAL_WEAK,
          [STATES.LOADING]: 'transparent',
          [STATES.FOCUSED]: theme.app.colors.BG_NEUTRAL_WEAKEST,
          [STATES.DISABLED]: 'transparent',
        };
    }
  }

  switch (color) {
    case COLORS.BRAND:
      return {
        [STATES.DEFAULT]: theme.app.colors.BG_BRAND_STRONGER,
        [STATES.HOVERED]: theme.app.colors.BG_BRAND_STRONG,
        [STATES.PRESSED]: theme.app.colors.BG_BRAND_STRONG,
        [STATES.LOADING]: theme.app.colors.BG_BRAND_WEAK,
        [STATES.FOCUSED]: theme.app.colors.BG_BRAND_STRONGER,
        [STATES.DISABLED]: theme.app.colors.BG_BRAND_WEAK,
      };
    case COLORS.ACCENT:
      return {
        [STATES.DEFAULT]: theme.app.colors.BG_ACCENT_NORMAL,
        [STATES.HOVERED]: theme.app.colors.BG_ACCENT_WEAK,
        [STATES.PRESSED]: theme.app.colors.BG_ACCENT_STRONG,
        [STATES.LOADING]: theme.app.colors.BG_ACCENT_WEAKER,
        [STATES.FOCUSED]: theme.app.colors.BG_ACCENT_WEAK,
        [STATES.DISABLED]: theme.app.colors.BG_ACCENT_WEAKER,
      };
    case COLORS.NEGATIVE:
      return {
        [STATES.DEFAULT]: theme.app.colors.BG_NEGATIVE_NORMAL,
        [STATES.HOVERED]: theme.app.colors.BG_NEGATIVE_WEAK,
        [STATES.PRESSED]: theme.app.colors.BG_NEGATIVE_STRONG,
        [STATES.LOADING]: theme.app.colors.BG_NEGATIVE_WEAKER,
        [STATES.FOCUSED]: theme.app.colors.BG_NEGATIVE_WEAK,
        [STATES.DISABLED]: theme.app.colors.BG_NEGATIVE_WEAKER,
      };
    case COLORS.NEUTRAL:
      return {
        [STATES.DEFAULT]: theme.app.colors.BG_NEUTRAL_WEAKER,
        [STATES.HOVERED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.PRESSED]: theme.app.colors.BG_NEUTRAL_NORMAL,
        [STATES.LOADING]: theme.app.colors.BG_NEUTRAL_WEAKER,
        [STATES.FOCUSED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.DISABLED]: theme.app.colors.BG_NEUTRAL_WEAKER,
      };

    case COLORS.BRAND_INVERTED:
      return {
        [STATES.DEFAULT]: theme.app.colors.BG_SURFACE,
        [STATES.HOVERED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.PRESSED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.LOADING]: theme.app.colors.BG_NEUTRAL_NORMAL,
        [STATES.FOCUSED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.DISABLED]: theme.app.colors.BG_NEUTRAL_NORMAL,
      };

    case COLORS.NEGATIVE_INVERTED:
      return {
        [STATES.DEFAULT]: theme.app.colors.BG_SURFACE,
        [STATES.HOVERED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.PRESSED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.LOADING]: theme.app.colors.BG_NEUTRAL_NORMAL,
        [STATES.FOCUSED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.DISABLED]: theme.app.colors.BG_NEUTRAL_NORMAL,
      };

    case COLORS.NEUTRAL_INVERTED:
      return {
        [STATES.DEFAULT]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.HOVERED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.PRESSED]: theme.app.colors.BG_NEUTRAL_NORMAL,
        [STATES.LOADING]: theme.app.colors.BG_NEUTRAL_NORMAL,
        [STATES.FOCUSED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.DISABLED]: theme.app.colors.BG_NEUTRAL_NORMAL,
      };
  }
};

const getFontSize = ({ size }) => {
  switch (size) {
    case SIZES.LARGE:
      return 18;
    case SIZES.MEDIUM:
      return 16;
    case SIZES.SMALL:
      return 14;
    case SIZES.X_SMALL:
      return 12;
  }
};

const getLineHeight = ({ size }) => {
  switch (size) {
    case SIZES.LARGE:
      return 18;
    case SIZES.MEDIUM:
      return 16;
    case SIZES.SMALL:
      return 14;
    case SIZES.X_SMALL:
      return 12;
  }
};

const getBorderWidth = (variant) => {
  if (variant === VARIANTS.OUTLINED) {
    return 1;
  }

  return 0;
};

//TODO: Recheck colors
const getBorderColorStyles = ({ variant, color, theme }) => {
  if (variant === VARIANTS.OUTLINED) {
    switch (color) {
      case COLORS.BRAND:
        return {
          [STATES.DEFAULT]: theme.app.colors.BORDER_BRAND_NORMAL,
          [STATES.HOVERED]: theme.app.colors.BORDER_BRAND_WEAK,
          [STATES.PRESSED]: theme.app.colors.BORDER_BRAND_STRONG,
          [STATES.LOADING]: theme.app.colors.BORDER_BRAND_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.BORDER_BRAND_WEAK,
          [STATES.DISABLED]: theme.app.colors.BORDER_BRAND_WEAKEST,
        };
      case COLORS.ACCENT:
        return {
          [STATES.DEFAULT]: theme.app.colors.BORDER_ACCENT_NORMAL,
          [STATES.HOVERED]: theme.app.colors.BORDER_ACCENT_WEAK,
          [STATES.PRESSED]: theme.app.colors.BORDER_ACCENT_STRONG,
          [STATES.LOADING]: theme.app.colors.BORDER_ACCENT_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.BORDER_ACCENT_WEAK,
          [STATES.DISABLED]: theme.app.colors.BORDER_ACCENT_WEAKEST,
        };
      case COLORS.NEGATIVE:
        return {
          [STATES.DEFAULT]: theme.app.colors.BORDER_NEGATIVE_NORMAL,
          [STATES.HOVERED]: theme.app.colors.BORDER_NEGATIVE_WEAK,
          [STATES.PRESSED]: theme.app.colors.BORDER_NEGATIVE_STRONG,
          [STATES.LOADING]: theme.app.colors.BORDER_NEGATIVE_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.BORDER_NEGATIVE_WEAK,
          [STATES.DISABLED]: theme.app.colors.BORDER_NEGATIVE_WEAKEST,
        };
      case COLORS.NEUTRAL:
        return {
          [STATES.DEFAULT]: theme.app.colors.BORDER_NEUTRAL_WEAK,
          [STATES.HOVERED]: theme.app.colors.BORDER_NEUTRAL_WEAK,
          [STATES.PRESSED]: theme.app.colors.BORDER_NEUTRAL_NORMAL,
          [STATES.LOADING]: theme.app.colors.BORDER_NEUTRAL_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.BORDER_NEUTRAL_WEAK,
          [STATES.DISABLED]: theme.app.colors.BORDER_NEUTRAL_WEAKEST,
        };
    }
  }

  return {
    [STATES.DEFAULT]: 'transparent',
    [STATES.HOVERED]: 'transparent',
    [STATES.PRESSED]: 'transparent',
    [STATES.LOADING]: 'transparent',
    [STATES.FOCUSED]: 'transparent',
    [STATES.DISABLED]: 'transparent',
  };
};

const getDropdownControllerBorderColorStyles = ({ color, theme }) => {
  switch (color) {
    case COLORS.BRAND:
      return {
        [STATES.DEFAULT]: theme.app.colors.green[80],
        [STATES.HOVERED]: theme.app.colors.BORDER_BRAND_NORMAL,
        [STATES.PRESSED]: theme.app.colors.BORDER_BRAND_STRONG,
        [STATES.LOADING]: theme.app.colors.BORDER_BRAND_WEAKEST,
        [STATES.FOCUSED]: theme.app.colors.BORDER_BRAND_WEAK,
        [STATES.DISABLED]: theme.app.colors.BORDER_BRAND_WEAKEST,
      };
    case COLORS.ACCENT:
      return {
        [STATES.DEFAULT]: theme.app.colors.BORDER_ACCENT_NORMAL,
        [STATES.HOVERED]: theme.app.colors.BORDER_ACCENT_WEAK,
        [STATES.PRESSED]: theme.app.colors.BORDER_ACCENT_STRONG,
        [STATES.LOADING]: theme.app.colors.BORDER_ACCENT_WEAKEST,
        [STATES.FOCUSED]: theme.app.colors.BORDER_ACCENT_WEAK,
        [STATES.DISABLED]: theme.app.colors.BORDER_ACCENT_WEAKEST,
      };
    case COLORS.NEGATIVE:
      return {
        [STATES.DEFAULT]: theme.app.colors.BORDER_NEGATIVE_NORMAL,
        [STATES.HOVERED]: theme.app.colors.BORDER_NEGATIVE_WEAK,
        [STATES.PRESSED]: theme.app.colors.BORDER_NEGATIVE_STRONG,
        [STATES.LOADING]: theme.app.colors.BORDER_NEGATIVE_WEAKEST,
        [STATES.FOCUSED]: theme.app.colors.BORDER_NEGATIVE_WEAK,
        [STATES.DISABLED]: theme.app.colors.BORDER_NEGATIVE_WEAKEST,
      };
    case COLORS.NEUTRAL:
      return {
        [STATES.DEFAULT]: theme.app.colors.BORDER_NEUTRAL_WEAK,
        [STATES.HOVERED]: theme.app.colors.BORDER_NEUTRAL_WEAK,
        [STATES.PRESSED]: theme.app.colors.BORDER_NEUTRAL_NORMAL,
        [STATES.LOADING]: theme.app.colors.BORDER_NEUTRAL_WEAKEST,
        [STATES.FOCUSED]: theme.app.colors.BORDER_NEUTRAL_WEAK,
        [STATES.DISABLED]: theme.app.colors.BORDER_NEUTRAL_WEAKEST,
      };
  }
};

const getBorderRadius = ({ size }) => {
  switch (size) {
    case SIZES.LARGE:
      return 8;
    case SIZES.MEDIUM:
      return 8;
    case SIZES.SMALL:
      return 8;
    case SIZES.X_SMALL:
      return 6;
  }
};

const getTextTransform = ({ uppercase }) => {
  if (uppercase) return 'uppercase';
  return 'none';
};

const getIconWidth = ({ size, iconOnly }) => {
  if (iconOnly) {
    switch (size) {
      case SIZES.LARGE:
        return 32;
      case SIZES.MEDIUM:
        return 28;
      case SIZES.SMALL:
        return 24;
      case SIZES.X_SMALL:
        return 20;
    }
  }

  switch (size) {
    case SIZES.LARGE:
      return 28;
    case SIZES.MEDIUM:
      return 24;
    case SIZES.SMALL:
      return 20;
    case SIZES.X_SMALL:
      return 16;
  }
};

const getIconHeight = ({ size, iconOnly }) => {
  if (iconOnly) {
    switch (size) {
      case SIZES.LARGE:
        return 32;
      case SIZES.MEDIUM:
        return 28;
      case SIZES.SMALL:
        return 24;
      case SIZES.X_SMALL:
        return 20;
    }
  }

  switch (size) {
    case SIZES.LARGE:
      return 28;
    case SIZES.MEDIUM:
      return 24;
    case SIZES.SMALL:
      return 20;
    case SIZES.X_SMALL:
      return 16;
  }
};

const getLoaderSize = ({ size, iconOnly }) => {
  if (iconOnly) {
    switch (size) {
      case SIZES.LARGE:
        return 32;
      case SIZES.MEDIUM:
        return 28;
      case SIZES.SMALL:
        return 24;
      case SIZES.X_SMALL:
        return 20;
    }
  }

  switch (size) {
    case SIZES.LARGE:
      return 28;
    case SIZES.MEDIUM:
      return 24;
    case SIZES.SMALL:
      return 20;
    case SIZES.X_SMALL:
      return 16;
  }
};

const getIconGap = (size) => {
  switch (size) {
    case SIZES.LARGE:
      return 8;
    case SIZES.MEDIUM:
      return 6;
    case SIZES.SMALL:
      return 4;
    case SIZES.X_SMALL:
      return 4;
  }
};

// const getDefaultContentPadding = ({ size, iconOnly }) => {
//   if (iconOnly) {
//     return 0;
//   }
//   switch (size) {
//     case SIZES.LARGE:
//       return 28;
//     case SIZES.MEDIUM:
//       return 24;
//     case SIZES.SMALL:
//       return 20;
//     case SIZES.X_SMALL:
//       return 16;
//   }
// };

const getOpticalAlignmentPadding = (size) => {
  switch (size) {
    case SIZES.LARGE:
      return 8;
    case SIZES.MEDIUM:
      return 6;
    case SIZES.SMALL:
      return 4;
    case SIZES.X_SMALL:
      return 4;
  }
};

const getDisabledBtnCss = ({ variant, colorStyles, backgroundColorStyles, borderColorStyles }) => {
  return css`
    cursor: not-allowed;
    pointer-events: none;
    color: ${colorStyles.DISABLED};
    background-color: ${backgroundColorStyles.DISABLED};
    box-shadow: inset 0 0 0 ${getBorderWidth(variant)}px ${borderColorStyles.DISABLED};
  `;
};

const getFocusedDisabledBtnCss = ({
  theme,
  variant,
  loading,
  colorStyles,
  backgroundColorStyles,
  borderColorStyles,
}) => {
  return css`
    outline: 2px solid ${theme.app.colors.BORDER_ACCENT_NORMAL};
    outline-offset: 2px;
    color: ${loading ? colorStyles.LOADING : colorStyles.FOCUSED};
    background-color: ${loading ? backgroundColorStyles.LOADING : backgroundColorStyles.FOCUSED};
    box-shadow: inset 0 0 0 ${getBorderWidth(variant)}px
      ${loading ? borderColorStyles.LOADING : borderColorStyles.FOCUSED};
  `;
};

const getOverlayBackground = ({ variant, color }) => {
  if (variant === VARIANTS.FILLED) {
    switch (color) {
      case COLORS.BRAND:
        return 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%)';
      case COLORS.NEGATIVE:
        return 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%)';
      case COLORS.ACCENT:
        return 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%)';
      case COLORS.NEUTRAL:
        return 'none';
    }

    return 'none';
  }
};

const isNotTransientProps = (prop) => {
  const StyledProps = Object.keys(STYlE_PROPS_DATA);
  return isPropValid(prop) || StyledProps.includes(prop) || prop === 'component';
};

const Root = styled(Flex, { shouldForwardProp: isNotTransientProps })`
  /* layout */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: ${getHeight}px;
  min-height: ${getHeight}px;
  width: ${getWidth};
  position: relative;
  text-decoration: none;

  /* space */
  padding-top: 0;
  padding-bottom: 0;

  /* color */
  background-color: ${({ loading, backgroundColorStyles }) =>
    loading ? backgroundColorStyles.LOADING : backgroundColorStyles.DEFAULT};

  /* border */
  border-radius: ${getBorderRadius}px;
  box-shadow: inset 0 0 0
    ${({ variant, loading, borderColorStyles }) =>
      `${getBorderWidth(variant)}px ${loading ? borderColorStyles.LOADING : borderColorStyles.DEFAULT}`};

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border-radius: ${getBorderRadius}px;
    background: ${getOverlayBackground};
    z-index: 1;
    pointer-events: none;
  }
`;

const ButtonRoot = styled(UnstyledButton, { shouldForwardProp: isNotTransientProps })`
  /* layout */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: ${getHeight}px;
  min-height: ${getHeight}px;
  text-decoration: none;

  /* space */
  padding-top: 0;
  padding-bottom: 0;
  padding-left: ${getInlinePadding}px;
  padding-right: ${getInlinePadding}px;

  /* color */
  color: ${({ loading, colorStyles }) => (loading ? colorStyles.LOADING : colorStyles.DEFAULT)};
  background-color: ${({ loading, backgroundColorStyles }) =>
    loading ? backgroundColorStyles.LOADING : backgroundColorStyles.DEFAULT};

  /* typography */
  font-size: ${getFontSize}px;
  font-weight: ${(props) => props.theme.app.fontWeights.medium};
  letter-spacing: -0.01em;
  line-height: ${getLineHeight}px;
  text-decoration: none;
  text-transform: ${getTextTransform};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};

  /* border */
  outline: none;
  border: none;
  border-radius: ${getBorderRadius}px 0px 0px ${getBorderRadius}px;

  /* actions */
  user-select: none;
  cursor: pointer;

  &:hover {
    color: ${({ colorStyles }) => colorStyles.HOVERED};
    background-color: ${({ backgroundColorStyles }) => backgroundColorStyles.HOVERED};
  }

  &:active {
    color: ${({ colorStyles }) => colorStyles.PRESSED};
    background-color: ${({ backgroundColorStyles }) => backgroundColorStyles.PRESSED};
  }

  /* not using &:disabled because we have scenarios where anchor link can also be disabled  */
  ${({ disabled }) => (disabled ? getDisabledBtnCss : null)}

  /* adding focus only when anchor is not disabled, this feature is by default for buttons  */
  &:focus-visible {
    ${({ disabled }) => (!disabled ? getFocusedDisabledBtnCss : null)}
  }
`;

const IconWrapper = styled(Box, { shouldForwardProp: isNotTransientProps })`
  width: ${getIconWidth}px;
  height: ${getIconHeight}px;
  flex-shrink: 0;

  > svg {
    width: 100%;
    height: 100%;
  }
`;

const DropdownToggleBtn = styled(UnstyledButton, { shouldForwardProp: isNotTransientProps })`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  color: ${({ loading, colorStyles }) => (loading ? colorStyles.LOADING : colorStyles.DEFAULT)};
  border-left: 1px solid ${({ borderColorStyles }) => borderColorStyles.DEFAULT};

  border-radius: 0px ${getBorderRadius}px ${getBorderRadius}px 0px;

  /* actions */
  user-select: none;
  cursor: pointer;
  position: relative;

  &:hover {
    color: ${({ colorStyles }) => colorStyles.HOVERED};
    background-color: ${({ backgroundColorStyles }) => backgroundColorStyles.HOVERED};
  }

  &:active {
    color: ${({ colorStyles }) => colorStyles.PRESSED};
    background-color: ${({ backgroundColorStyles }) => backgroundColorStyles.PRESSED};
  }

  /* not using &:disabled because we have scenarios where anchor link can also be disabled  */
  ${({ disabled }) => (disabled ? getDisabledBtnCss : null)}

  /* adding focus only when anchor is not disabled, this feature is by default for buttons  */
  &:focus-visible {
    ${({ disabled }) => (!disabled ? getFocusedDisabledBtnCss : null)}
  }
`;

const DropdownContainer = styled(Box, { shouldForwardProp: isNotTransientProps })`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: ${({ theme }) => theme.app.colors.BG_SURFACE};
  z-index: 1;
  border-radius: 12px;
  margin-top: 8px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.03);
`;

const DropdownButton = forwardRef(({ loading, ...props }, ref) => {
  const {
    variant,
    color,
    size,
    icon,
    iconLeft,
    iconRight,
    text,
    loadingText,
    children,
    iconWrapperProps,
    dropdownNode,
    onClick,
    onRightBtnClick = noop,
    hideDropdown,
    dropdownBtnContent,
    btnProps,
    dropdownToggleBtnProps,
    dropdownContainerProps,
    ...rest
  } = props;

  const theme = useMantineTheme();
  const dropdownToggleBtnRef = useRef(null);
  const dropdownContainerRef = useRef(null);

  const backgroundColorStyles = getBackgroundColorStyles({ variant, color, theme });
  const colorStyles = getColorStyles({ variant, color, theme });
  const borderColorStyles = getBorderColorStyles({ variant, color, theme });
  const dropdownControllerBorderColorStyles = getDropdownControllerBorderColorStyles({ color, theme });
  const iconOnly = !!icon && !text && !children;
  const hasIconLeft = !!iconLeft;
  const hasIconRight = !!iconRight;
  const iconGap = getIconGap(size);
  // const defaultContentPadding = getDefaultContentPadding({ size, iconOnly });
  const opticalAlignmentPadding = getOpticalAlignmentPadding(size);
  const loaderSize = getLoaderSize({ size, iconOnly });

  let contentNode = null;

  if (iconOnly) {
    contentNode = (
      <IconWrapper size={size} iconOnly={iconOnly} loading={loading} {...iconWrapperProps}>
        {loading ? <Loader size={loaderSize} color={colorStyles.DEFAULT} /> : icon}
      </IconWrapper>
    );
  } else {
    if (loading) {
      if (loadingText) {
        contentNode = loadingText;
      } else if (!iconOnly && !hasIconLeft && !hasIconRight) {
        contentNode = <Loader size={loaderSize} color={colorStyles.DEFAULT} />;
      } else {
        contentNode = children || text || null;
      }
    } else {
      contentNode = children || text || null;
    }
  }

  /* adding padding on both sides for optical alignment, in case any icon is present */
  const contentPaddingLeft = hasIconLeft ? iconGap : hasIconRight ? opticalAlignmentPadding : 2;
  const contentPaddingRight = hasIconRight ? iconGap : hasIconLeft ? opticalAlignmentPadding : 2;

  return (
    <Root
      ref={ref}
      size={size}
      color={color}
      variant={variant}
      loading={loading}
      iconOnly={iconOnly}
      colorStyles={colorStyles}
      borderColorStyles={borderColorStyles}
      backgroundColorStyles={backgroundColorStyles}
      {...rest}
    >
      <ButtonRoot
        size={size}
        color={color}
        variant={variant}
        loading={loading}
        iconOnly={iconOnly}
        colorStyles={colorStyles}
        borderColorStyles={borderColorStyles}
        backgroundColorStyles={backgroundColorStyles}
        onClick={onClick}
        {...btnProps}
      >
        {hasIconLeft && (
          <IconWrapper size={size} iconOnly={iconOnly} loading={loading} {...iconWrapperProps}>
            {loading ? <Loader size={loaderSize} color={colorStyles.DEFAULT} /> : iconLeft}
          </IconWrapper>
        )}
        <Box pl={`${contentPaddingLeft}px`} pr={`${contentPaddingRight}px`}>
          {contentNode}
        </Box>
        {hasIconRight && (
          <IconWrapper size={size} iconOnly={iconOnly} loading={loading} {...iconWrapperProps}>
            {loading ? <Loader size={loaderSize} color={colorStyles.DEFAULT} /> : iconRight}
          </IconWrapper>
        )}
      </ButtonRoot>
      {hideDropdown ? null : (
        <DropdownToggleBtn
          ref={dropdownToggleBtnRef}
          size={size}
          loading={loading}
          borderColorStyles={dropdownControllerBorderColorStyles}
          colorStyles={colorStyles}
          backgroundColorStyles={backgroundColorStyles}
          onClick={onRightBtnClick}
          {...dropdownToggleBtnProps}
        >
          {dropdownBtnContent ? (
            dropdownBtnContent
          ) : (
            <IconWrapper size={size} iconOnly {...iconWrapperProps}>
              <ChevronDownIcon />
            </IconWrapper>
          )}
        </DropdownToggleBtn>
      )}

      {dropdownNode ? (
        <DropdownContainer ref={dropdownContainerRef} {...dropdownContainerProps}>
          {dropdownNode}
        </DropdownContainer>
      ) : null}
    </Root>
  );
});

DropdownButton.VARIANTS = VARIANTS;
DropdownButton.COLORS = COLORS;
DropdownButton.SIZES = SIZES;

DropdownButton.displayName = 'Button';

DropdownButton.defaultProps = {
  variant: VARIANTS.FILLED,
  color: COLORS.BRAND,
  size: SIZES.MEDIUM,
};

export default DropdownButton;
