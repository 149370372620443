import { getEnv, isProductionHostname } from '~/utils';

const GOOGLE_LOGIN_CLIENT_ID_BY_ENVIRONMENT = {
  PRODUCTION: '521387537993-8fs84pvr9s2dh6n1cdedkffp2n5ihrjj.apps.googleusercontent.com',
  STAGING: '137457888237-qkgb7nm9qfkl63baheut9aucvj2f15ls.apps.googleusercontent.com',
};

// const GOOGLE_PERMISSIONS_CLIENT_ID_BY_ENVIRONMENT = {
//   PRODUCTION: '758825435163-hb4ng1udfaujub2dfvp471t8hepdtce0.apps.googleusercontent.com',
//   STAGING: '50999275446-ekmltu8smgp9jkj5qskjea3g43fv3iud.apps.googleusercontent.com',
// };

const GOOGLE_PERMISSIONS_CLIENT_ID_BY_ENVIRONMENT = {
  PRODUCTION: '521387537993-8fs84pvr9s2dh6n1cdedkffp2n5ihrjj.apps.googleusercontent.com',
  STAGING: '137457888237-qkgb7nm9qfkl63baheut9aucvj2f15ls.apps.googleusercontent.com',
};

export const GOOGLE_LOGIN_CLIENT_ID = (() => {
  const { hostname } = location;
  if (isProductionHostname(hostname)) {
    return GOOGLE_LOGIN_CLIENT_ID_BY_ENVIRONMENT.PRODUCTION;
  }
  return GOOGLE_LOGIN_CLIENT_ID_BY_ENVIRONMENT.STAGING;
})();

export const GOOGLE_PERMISSIONS_CLIENT_ID = (() => {
  const { hostname } = location;
  if (isProductionHostname(hostname)) {
    return GOOGLE_PERMISSIONS_CLIENT_ID_BY_ENVIRONMENT.PRODUCTION;
  }
  return GOOGLE_PERMISSIONS_CLIENT_ID_BY_ENVIRONMENT.STAGING;
})();

export const GOOGLE_REDIRECT_URI = (() => {
  if (getEnv() === 'PRODUCTION') {
    return 'https://kello.ai';
  } else if (getEnv() === 'STAGING') {
    return 'https://staging.kello.ai';
  }
  return 'https://localhost:8085';
})();

export const FORMSPARK_FORM_ID = '2u76IUKWq';

export const CHATWOOT_WEBSITE_TOKEN = 'bjfqXyr8RSHcJpMLAa2ymNYk';
export const CHATWOOT_IDENTITY_VALIDATION_KEY = 'kLEJhC6u9ABLrqR9jeezH89D';

export const getGoogleOAuthUrl = ({ hintEmail = '', isReconnect = false }) => {
  let redirectUri;
  let clientCode;

  if (getEnv() === 'PRODUCTION') {
    if (isReconnect) {
      redirectUri = 'https://kello.ai/app/reconnect/google';
    } else {
      redirectUri = 'https://kello.ai/app/integration/google';
    }
    clientCode = GOOGLE_PERMISSIONS_CLIENT_ID_BY_ENVIRONMENT.PRODUCTION;
  } else if (getEnv() === 'STAGING') {
    if (isReconnect) {
      redirectUri = 'https://staging.kello.ai/app/reconnect/google';
    } else {
      redirectUri = 'https://staging.kello.ai/app/integration/google';
    }
    clientCode = GOOGLE_PERMISSIONS_CLIENT_ID_BY_ENVIRONMENT.STAGING;
  } else {
    if (isReconnect) {
      redirectUri = 'https://localhost:8085/app/reconnect/google';
    } else {
      redirectUri = 'https://localhost:8085/app/integration/google';
    }
    clientCode = GOOGLE_PERMISSIONS_CLIENT_ID_BY_ENVIRONMENT.STAGING;
  }
  return `https://accounts.google.com/o/oauth2/v2/auth?prompt=&access_type=offline&redirect_uri=${redirectUri}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly&include_granted_scopes=true&login_hint=${hintEmail}&response_type=code&client_id=${clientCode}`;
};
