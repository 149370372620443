import { Box, Flex, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';

import { Button, Input } from '~/components/atoms';
import { ParaXSmallStrong } from '~/components/typography';

const CollectionActionModal = ({ innerProps, context, id }) => {
  const theme = useMantineTheme();
  const { collection, onSaveChanges, requestStates = {} } = innerProps;

  const isEditing = !!collection;

  const form = useForm({ initialValues: { name: collection?.name || '', description: collection?.description || '' } });

  const handleOnSubmit = (values) => {
    onSaveChanges(values);
    context.closeModal(id);
  };

  let errorNode;

  if (requestStates.rejected) {
    const errorMessage = requestStates.error?.errorMessage || `Failed to ${isEditing ? 'save' : 'create'} collection.`;
    errorNode = (
      <ParaXSmallStrong c={theme.app.colors.TEXT_NEGATIVE_STRONG} ta="center">
        {errorMessage}
      </ParaXSmallStrong>
    );
  }

  return (
    <Box component="form" onSubmit={form.onSubmit(handleOnSubmit)}>
      <Box p="24px">
        <Input
          styles={{
            input: {
              height: '56px',
            },
          }}
          label="Name Your Collection"
          placeholder="Collection"
          {...form.getInputProps('name')}
        />
        <Input
          styles={{
            input: {
              height: '56px',
            },
          }}
          label="Description (Optional)"
          placeholder="Description"
          mt="16px"
          {...form.getInputProps('description')}
        />
      </Box>
      <Flex
        p="24px"
        justify="space-between"
        align="center"
        style={{
          borderTop: `1px solid ${theme.app.colors.BORDER_NEUTRAL_WEAKEST}`,
        }}
      >
        {errorNode ? errorNode : <Box />}
        <Button
          size={Button.SIZES.SMALL}
          ml="12px"
          type="submit"
          loading={requestStates.pending}
          text={isEditing ? 'Save Changes' : 'Create Collection'}
        />
      </Flex>
    </Box>
  );
};

export default CollectionActionModal;
