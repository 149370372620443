import styled from '@emotion/styled';
import { Box, Flex } from '@mantine/core';

import { ParaXSmall, TitleMedium } from '~/components/typography';

import Illustration from '~/assets/images/modals/message-modal/check-illustration.svg';

const MessageModal = ({ innerProps }) => {
  const { message, metadata } = innerProps;

  return (
    <Root>
      <IllustrationWrapper mb="32px">
        <Illustration />
      </IllustrationWrapper>
      <TitleMedium ta="center">{message}</TitleMedium>
      <ParaXSmall ta="center" mt="12px">
        {metadata}
      </ParaXSmall>
    </Root>
  );
};

export default MessageModal;

const Root = styled(Flex)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const IllustrationWrapper = styled(Box)`
  width: 84px;
  height: 81px;
  flex-shrink: 0;

  > svg {
    width: 100%;
    height: 100%;
  }
`;
