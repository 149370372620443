import { css, keyframes } from '@emotion/react';

export const shimmerKf = () => keyframes`
    from{
        background-position-x: 100%;
    }
    to {
        background-position-x: -30%
    }
`;

export const shimmer = (ms = 1000, timerFn = 'linear') => css`
  animation: ${shimmerKf()} ${ms}ms ${timerFn};
  animation-iteration-count: infinite;
`;
