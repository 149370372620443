import baseColors from './baseColors';

const colors = {
  /************ BASE COLORS ************/
  ...baseColors,

  /************ BG ************/
  // BRAND
  BG_BRAND_WEAKEST: baseColors.green[10],
  BG_BRAND_WEAKER: baseColors.green[20],
  BG_BRAND_WEAK: baseColors.green[30],
  BG_BRAND_NORMAL: baseColors.green[60],
  BG_BRAND_STRONG: baseColors.green[70],
  BG_BRAND_STRONGER: baseColors.green[90],
  BG_BRAND_STRONGEST: baseColors.green[100],

  //ACCENT
  BG_ACCENT_WEAKEST: baseColors.orange[10],
  BG_ACCENT_WEAKER: baseColors.orange[20],
  BG_ACCENT_WEAK: baseColors.orange[50],
  BG_ACCENT_NORMAL: baseColors.orange[60],
  BG_ACCENT_STRONG: baseColors.orange[70],
  BG_ACCENT_STRONGER: baseColors.orange[80],
  BG_ACCENT_STRONGEST: baseColors.orange[90],

  // NEGATIVE
  BG_NEGATIVE_WEAKEST: baseColors.red[10],
  BG_NEGATIVE_WEAKER: baseColors.red[20],
  BG_NEGATIVE_WEAK: baseColors.red[50],
  BG_NEGATIVE_NORMAL: baseColors.red[60],
  BG_NEGATIVE_STRONG: baseColors.red[70],
  BG_NEGATIVE_STRONGER: baseColors.red[80],
  BG_NEGATIVE_STRONGEST: baseColors.red[90],

  // POSITIVE
  BG_POSITIVE_WEAKEST: baseColors.green[10],
  BG_POSITIVE_WEAKER: baseColors.green[20],
  BG_POSITIVE_WEAK: baseColors.green[50],
  BG_POSITIVE_NORMAL: baseColors.green[60],
  BG_POSITIVE_STRONG: baseColors.green[70],
  BG_POSITIVE_STRONGER: baseColors.green[80],
  BG_POSITIVE_STRONGEST: baseColors.green[90],

  // WARNING
  BG_WARNING_WEAKEST: baseColors.yellow[10],
  BG_WARNING_WEAKER: baseColors.yellow[20],
  BG_WARNING_WEAK: baseColors.yellow[50],
  BG_WARNING_NORMAL: baseColors.yellow[60],
  BG_WARNING_STRONG: baseColors.yellow[70],
  BG_WARNING_STRONGER: baseColors.yellow[80],
  BG_WARNING_STRONGEST: baseColors.yellow[90],

  // NEUTRAL
  BG_SURFACE: baseColors.grey[0],
  BG_NEUTRAL_WEAKEST: baseColors.grey[5],
  BG_NEUTRAL_WEAKER: baseColors.grey[10],
  BG_NEUTRAL_WEAK: baseColors.grey[20],
  BG_NEUTRAL_NORMAL: baseColors.grey[30],
  BG_NEUTRAL_STRONG: baseColors.grey[80],
  BG_NEUTRAL_STRONGER: baseColors.grey[95],
  BG_NEUTRAL_STRONGEST: baseColors.grey[100],

  /************ TEXT ************/
  // BRAND
  TEXT_BRAND_WEAKEST: baseColors.green[30],
  TEXT_BRAND_WEAK: baseColors.green[40],
  TEXT_BRAND_NORMAL: baseColors.green[80],
  TEXT_BRAND_STRONG: baseColors.green[100],

  // ACCENT
  TEXT_ACCENT_WEAKEST: baseColors.orange[30],
  TEXT_ACCENT_WEAK: baseColors.orange[50],
  TEXT_ACCENT_NORMAL: baseColors.orange[60],
  TEXT_ACCENT_STRONG: baseColors.orange[70],

  // NEGATIVE
  TEXT_NEGATIVE_WEAKEST: baseColors.red[30],
  TEXT_NEGATIVE_WEAK: baseColors.red[50],
  TEXT_NEGATIVE_NORMAL: baseColors.red[60],
  TEXT_NEGATIVE_STRONG: baseColors.red[70],

  // POSITIVE
  TEXT_POSITIVE_WEAKEST: baseColors.green[30],
  TEXT_POSITIVE_WEAK: baseColors.green[50],
  TEXT_POSITIVE_NORMAL: baseColors.green[60],
  TEXT_POSITIVE_STRONG: baseColors.green[70],

  // WARNING
  TEXT_WARNING_WEAKEST: baseColors.yellow[30],
  TEXT_WARNING_WEAK: baseColors.yellow[50],
  TEXT_WARNING_NORMAL: baseColors.yellow[60],
  TEXT_WARNING_STRONG: baseColors.yellow[70],

  // NEUTRAL
  TEXT_INVERTED: baseColors.grey[0],
  TEXT_NEUTRAL_WEAKEST: baseColors.grey[30],
  TEXT_NEUTRAL_WEAK: baseColors.grey[50],
  TEXT_NEUTRAL_NORMAL: baseColors.grey[80],
  TEXT_NEUTRAL_STRONG: baseColors.grey[100],

  /************ BORDER ************/
  // BRAND
  BORDER_BRAND_WEAKEST: baseColors.green[20],
  BORDER_BRAND_WEAK: baseColors.green[30],
  BORDER_BRAND_NORMAL: baseColors.green[40],
  BORDER_BRAND_STRONG: baseColors.green[70],

  // ACCENT
  BORDER_ACCENT_WEAKEST: baseColors.orange[20],
  BORDER_ACCENT_WEAK: baseColors.orange[30],
  BORDER_ACCENT_NORMAL: baseColors.orange[60],
  BORDER_ACCENT_STRONG: baseColors.orange[70],

  // NEGATIVE
  BORDER_NEGATIVE_WEAKEST: baseColors.red[20],
  BORDER_NEGATIVE_WEAK: baseColors.red[30],
  BORDER_NEGATIVE_NORMAL: baseColors.red[50],
  BORDER_NEGATIVE_STRONG: baseColors.red[70],

  // POSITIVE
  BORDER_POSITIVE_WEAKEST: baseColors.green[20],
  BORDER_POSITIVE_WEAK: baseColors.green[30],
  BORDER_POSITIVE_NORMAL: baseColors.green[50],
  BORDER_POSITIVE_STRONG: baseColors.green[70],

  // WARNING
  BORDER_WARNING_WEAKEST: baseColors.yellow[20],
  BORDER_WARNING_WEAK: baseColors.yellow[30],
  BORDER_WARNING_NORMAL: baseColors.yellow[50],
  BORDER_WARNING_STRONG: baseColors.yellow[70],

  // NEUTRAL
  BORDER_INVERTED: baseColors.grey[5],
  BORDER_NEUTRAL_WEAKEST: baseColors.grey[20],
  BORDER_NEUTRAL_WEAK: baseColors.grey[30],
  BORDER_NEUTRAL_NORMAL: baseColors.grey[40],
  BORDER_NEUTRAL_STRONG: baseColors.grey[80],

  /************ ICON ************/
  // BRAND
  ICON_BRAND_WEAKEST: baseColors.green[40],
  ICON_BRAND_WEAK: baseColors.green[50],
  ICON_BRAND_NORMAL: baseColors.green[70],
  ICON_BRAND_STRONG: baseColors.green[90],

  // ACCENT
  ICON_ACCENT_WEAKEST: baseColors.orange[30],
  ICON_ACCENT_WEAK: baseColors.orange[50],
  ICON_ACCENT_NORMAL: baseColors.orange[60],
  ICON_ACCENT_STRONG: baseColors.orange[70],

  // NEGATIVE
  ICON_NEGATIVE_WEAKEST: baseColors.red[30],
  ICON_NEGATIVE_WEAK: baseColors.red[50],
  ICON_NEGATIVE_NORMAL: baseColors.red[60],
  ICON_NEGATIVE_STRONG: baseColors.red[70],

  // POSITIVE
  ICON_POSITIVE_WEAKEST: baseColors.green[30],
  ICON_POSITIVE_WEAK: baseColors.green[50],
  ICON_POSITIVE_NORMAL: baseColors.green[60],
  ICON_POSITIVE_STRONG: baseColors.green[70],

  // WARNING
  ICON_WARNING_WEAKEST: baseColors.yellow[30],
  ICON_WARNING_WEAK: baseColors.yellow[50],
  ICON_WARNING_NORMAL: baseColors.yellow[60],
  ICON_WARNING_STRONG: baseColors.yellow[70],

  // NEUTRAL
  ICON_INVERTED: baseColors.grey[0],
  ICON_NEUTRAL_WEAKEST: baseColors.grey[40],
  ICON_NEUTRAL_WEAK: baseColors.grey[50],
  ICON_NEUTRAL_NORMAL: baseColors.grey[70],
  ICON_NEUTRAL_STRONG: baseColors.grey[100],
};

export default colors;
