import styled from '@emotion/styled';
import { Box, Flex } from '@mantine/core';

export const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  padding: 16px 24px;
  z-index: 1;
  border-bottom: 1px solid ${({ theme }) => theme.app.colors.BORDER_NEUTRAL_WEAKEST};
`;

export const Body = styled(Box)``;

export const Footer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px 24px;
  z-index: 1;
  border-top: 1px solid ${({ theme }) => theme.app.colors.BORDER_NEUTRAL_WEAKEST};
`;

export const NoResultsIllustrationContainer = styled(Box)`
  width: 150px;
  height: 124px;
  flex-shrink: 0;
`;

export const FiltersContainer = styled(Box)`
  overflow-y: auto;
  max-height: 500px;
  margin-bottom: 72px;
`;

export const HeaderIcon = styled(Box)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.app.colors.ICON_NEUTRAL_STRONG};
`;
