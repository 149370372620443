import queryString from 'query-string';

import httpClient from './client';

export const fetch = (options = {}) => {
  return httpClient.get(`/api/v1/org/`, options);
};

export const fetchUsersForOrg = (options = {}) => {
  return httpClient.get(`/api/v1/org/users`, options);
};

export const fetchCandidateArchiveReasons = (options = {}) => {
  return httpClient.get(`/api/v1/org/archive-reasons`, options);
};

export const fetchJobApplicationStages = (payload, options = {}) => {
  const query = queryString.stringify(payload);
  return httpClient.get(`/api/v1/org/job-application-stages?` + query, options);
};
