import { Box, Divider, Flex, useMantineTheme } from '@mantine/core';
import { useEffect } from 'react';
import { useForm } from '@mantine/form';
import { debounce } from 'lodash-es';
import plur from 'plur';

import { useRequestStates } from '~/hooks';
import { extractResponseError } from '~/api/utils';
import { Button } from '~/components/atoms';
import { LabelXSmall, LabelXXSmall, ParaXSmall, TitleXSmall } from '~/components/typography';
import { SEARCH_FILTER_INPUT_TYPES } from '~/constants';
import { getAppliedSearchFilters } from '~/utils';

import FilterIcon from '~/assets/icons/filter-v2.svg';
import CrossIcon from '~/assets/icons/close.svg';

import * as Styles from './SearchFiltersModal.styled';
import Filter from './components/Filter';

const SearchFiltersModal = ({ innerProps, context, id }) => {
  const {
    jobId,
    form: inputForm,
    filters,
    onApplyFilters,
    onRemoveFilters,
    fetchResultsCountApi,
    hideResultsCount = false,
    trackEvent,
  } = innerProps;
  const theme = useMantineTheme();

  const [fetchFilterTalentPoolCountRequestStates, fetchFilterTalentPoolCountRequestHandlers] = useRequestStates();

  const form = useForm({
    initialValues: { ...inputForm.values },
  });

  const getFilterTalentPoolResultsCount = async () => {
    const formValues = form.values;
    const payload = {};

    for (const key in formValues) {
      const value = formValues[key];
      if (![null, undefined, ''].includes(value)) {
        payload[key] = value;
      }
    }

    if (jobId) {
      payload.jobId = jobId;
    }
    try {
      fetchFilterTalentPoolCountRequestHandlers.pending();
      const resp = await fetchResultsCountApi(payload);
      fetchFilterTalentPoolCountRequestHandlers.fulfilled(resp.result);
    } catch (error) {
      const errorObj = extractResponseError(error);
      fetchFilterTalentPoolCountRequestHandlers.rejected(errorObj.errorMessage);
    }
  };

  const handleApplyFiltersBtnClick = () => {
    context.closeModal(id);
    onApplyFilters(form.values);
  };

  const handleClearAllFilters = () => {
    onRemoveFilters();
    context.closeModal(id);
  };

  const handleCloseModal = () => {
    context.closeModal(id);
    trackEvent('closed_modal', {
      modal: 'search_filters_modal', // TODO: Discuss the name
    });
  };

  const debouncedGetFilterTalentPoolResultsCount = debounce(getFilterTalentPoolResultsCount, 500);

  useEffect(() => {
    if (fetchResultsCountApi) {
      debouncedGetFilterTalentPoolResultsCount();
    }

    return () => {
      debouncedGetFilterTalentPoolResultsCount.cancel();
    };
  }, [form?.values, fetchResultsCountApi]);

  const numberOfFiltersApplied = getAppliedSearchFilters(form.values).length;

  let numberOfFiltersNode;

  if (numberOfFiltersApplied) {
    numberOfFiltersNode = (
      <Flex
        w="20px"
        h="20px"
        bg={theme.app.colors.BG_BRAND_STRONGEST}
        align="center"
        justify="center"
        style={{ borderRadius: '50%' }}
        mr="8px"
      >
        <LabelXXSmall c={theme.app.colors.TEXT_INVERTED} ta="center">
          {numberOfFiltersApplied}
        </LabelXXSmall>
      </Flex>
    );
  }

  const numberOfCandidatesFound =
    fetchFilterTalentPoolCountRequestStates.data?.count ||
    fetchFilterTalentPoolCountRequestStates.data?.totalCandidates ||
    0;

  const renderFilter = (filter) => {
    const { inputType, data, filterName, filterLabel } = filter;

    switch (inputType) {
      case SEARCH_FILTER_INPUT_TYPES.MULTI_CHOICE:
        return (
          <Filter
            key={filterName}
            title={filterLabel}
            placeholder={`Select ${filterLabel}`}
            isMultiSelect
            options={data}
            value={form.values[filterName]}
            onChange={(val) => form.setFieldValue(filterName, val)}
          />
        );
      case SEARCH_FILTER_INPUT_TYPES.SINGLE_CHOICE:
        return (
          <Filter
            key={filterName}
            title={filterLabel}
            placeholder={`Select ${filterLabel}`}
            isSelect
            options={data}
            value={form.values[filterName]}
            onChange={(val) => form.setFieldValue(filterName, val)}
          />
        );
      case SEARCH_FILTER_INPUT_TYPES.DATE_RANGE:
        return (
          <Filter
            key={filterName}
            title={filterLabel}
            placeholder={`Select ${filterLabel}`}
            isDatePicker
            value={form.values[filterName]}
            onChange={(val) => form.setFieldValue(filterName, val)}
          />
        );
      case SEARCH_FILTER_INPUT_TYPES.NUMERICAL_RANGE:
        return (
          <Filter
            key={filterName}
            title={filterLabel}
            placeholder={`Select ${filterLabel}`}
            isRange
            maxInputForRange={data.max}
            value={[form.values[filterName][0], form.values[filterName][1]]}
            onChange={(val) => form.setFieldValue(filterName, [val[0], val[1]])}
            valueUnit={filter.unit}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box bg={theme.app.colors.BG_NEUTRAL_WEAKEST}>
      <Styles.Header>
        <Flex align="center">
          <Styles.HeaderIcon component={FilterIcon} />
          <TitleXSmall ml="8px">Filters</TitleXSmall>
        </Flex>
        <Styles.HeaderIcon
          component={CrossIcon}
          c={theme.app.colors.ICON_NEUTRAL_WEAK}
          style={{ cursor: 'pointer' }}
          onClick={handleCloseModal}
        />
      </Styles.Header>
      <Styles.FiltersContainer px="24px">
        {filters.filter((filter) => !filter.isHidden).map(renderFilter)}
      </Styles.FiltersContainer>
      <Divider />
      <Styles.Footer>
        <Button
          size={Button.SIZES.X_SMALL}
          color={Button.COLORS.NEUTRAL}
          variant={Button.VARIANTS.OUTLINED}
          onClick={handleClearAllFilters}
        >
          <Flex align="center">
            {numberOfFiltersNode}{' '}
            <LabelXSmall c={theme.app.colors.TEXT_NEUTRAL_NORMAL} fw={theme.app.fontWeights.medium}>
              Clear All
            </LabelXSmall>
          </Flex>
        </Button>

        <Flex align="center">
          {hideResultsCount ? null : (
            <ParaXSmall>
              {fetchFilterTalentPoolCountRequestStates.pending
                ? 'Loading...'
                : `Showing ${numberOfCandidatesFound} ${plur('Candidate', numberOfCandidatesFound)}`}
            </ParaXSmall>
          )}
          <Button onClick={handleApplyFiltersBtnClick} size={Button.SIZES.X_SMALL} text="Apply Filters" ml="16px" />
        </Flex>
      </Styles.Footer>
    </Box>
  );
};

export default SearchFiltersModal;
