import { Avatar, Tooltip, Box, Flex, useMantineTheme } from '@mantine/core';
import styled from '@emotion/styled';
import parse from 'html-react-parser';

import { LabelMediumStrong, LabelSmall, LabelXSmall, LabelXXSmall, TitleXSmall } from '~/components/typography';
import { Button } from '~/components/atoms';
import { JOB_TYPES_MAP } from '~/constants';
import { useAppContext } from '~/contexts/app';

import BagIconComponent from '~/assets/icons/bag.svg';
import CrossIconComponent from '~/assets/icons/cross.svg';

const JobDetailsModal = ({ innerProps, context, id }) => {
  const { organisation } = useAppContext();
  const theme = useMantineTheme();
  const TOTAL_HIRING_MEMBERS_SHOWN = 5;
  const { job, collaborators } = innerProps;

  const handleCloseModal = () => {
    context.closeModal(id);
  };

  return (
    <Box>
      <Header>
        <Flex align="center">
          <BagIcon component={BagIconComponent} />
          <TitleXSmall>Job Details</TitleXSmall>
        </Flex>
        <CrossIcon component={CrossIconComponent} onClick={handleCloseModal} />
      </Header>

      <Box p="0px 24px">
        <Flex
          align="center"
          justify="space-between"
          p="24px 0"
          style={{ borderBottom: `1px solid ${theme.app.colors.BORDER_NEUTRAL_WEAKEST}` }}
        >
          <Flex align="center" justify="start">
            {organisation.logo ? (
              <OrganisationLogo component="img" alt="Organisation Logo" src={organisation.logo} mr="16px" />
            ) : null}
            <JobDescriptionContainer>
              <LabelMediumStrong mb="12px" c={theme.app.colors.TEXT_NEUTRAL_STRONG}>
                {job.title}
              </LabelMediumStrong>
              <Flex align="center">
                {job.locations.length ? (
                  <>
                    <LabelXSmall c={theme.app.colors.TEXT_NEUTRAL_WEAK} truncate maw="100%">
                      {job.locations.join(', ')}
                    </LabelXSmall>
                    <Dot />
                  </>
                ) : null}
                <LabelXSmall c={theme.app.colors.TEXT_NEUTRAL_WEAK}>{JOB_TYPES_MAP[job.type] || job.type}</LabelXSmall>

                {job.department ? (
                  <>
                    <Dot />
                    <LabelXSmall c={theme.app.colors.TEXT_NEUTRAL_WEAK}>{job.department}</LabelXSmall>
                  </>
                ) : null}
              </Flex>
            </JobDescriptionContainer>
          </Flex>

          <Flex direction="column" align="flex-end" justify="flex-end">
            <LabelXXSmall mb="12px" c={theme.app.colors.TEXT_NEUTRAL_NORMAL}>
              Hiring Team
            </LabelXXSmall>
            <Tooltip.Group openDelay={300} closeDelay={100}>
              <Avatar.Group spacing="6px">
                {collaborators.slice(0, TOTAL_HIRING_MEMBERS_SHOWN).map((member) => (
                  <Tooltip position="bottom-end" label={member.name} key={member.id} withArrow>
                    <Avatar
                      src={member.picture}
                      alt={member.name}
                      color={theme.app.colors.TEXT_ACCENT_NORMAL}
                      bg={theme.app.colors.BG_ACCENT_WEAKEST}
                      size="24px"
                      style={{
                        borderRadius: '100%',
                      }}
                    >
                      {member.name[0].toUpperCase()}
                    </Avatar>
                  </Tooltip>
                ))}
                {collaborators.length > TOTAL_HIRING_MEMBERS_SHOWN && (
                  <Avatar size="24px" style={{ borderRadius: '100%' }}>
                    +{collaborators.length - TOTAL_HIRING_MEMBERS_SHOWN}
                  </Avatar>
                )}
              </Avatar.Group>
            </Tooltip.Group>
          </Flex>
        </Flex>

        <Box pt="24px">
          <LabelMediumStrong mb="24px" c={theme.app.colors.TEXT_NEUTRAL_STRONG}>
            About the job
          </LabelMediumStrong>

          <Box mah="340px" style={{ overflowY: 'auto' }} mb="89px" pb="16px">
            <LabelSmall c={theme.app.colors.TEXT_NEUTRAL_NORMAL}>{parse(job.description)}</LabelSmall>
          </Box>
        </Box>
      </Box>
      <Footer>
        <Button mr="24px" size={Button.SIZES.SMALL} onClick={handleCloseModal} text="Done" />
      </Footer>
    </Box>
  );
};

export default JobDetailsModal;

const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.app.colors.BORDER_NEUTRAL_WEAKEST};
`;

const BagIcon = styled(Box)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 12px;
  color: ${({ theme }) => theme.app.colors.ICON_NEUTRAL_NORMAL};
`;

const CrossIcon = styled(Box)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.app.colors.ICON_NEUTRAL_WEAK};
`;

const JobDescriptionContainer = styled(Flex)`
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;

const Dot = styled(Box)`
  width: 3px;
  height: 3px;
  border-radius: 100%;
  margin: 0 6px;
  background: ${({ theme }) => theme.app.colors.BG_NEUTRAL_NORMAL};
`;

export const Footer = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 24px 0;
  background-color: ${({ theme }) => theme.app.colors.BG_SURFACE};
  z-index: 1;
  border-top: 1px solid ${({ theme }) => theme.app.colors.BORDER_NEUTRAL_WEAKEST};
`;

export const OrganisationLogo = styled(Box)`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 4px;
`;
