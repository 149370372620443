import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Global, ThemeProvider } from '@emotion/react';
import { Box, Flex, useMantineTheme } from '@mantine/core';
import * as Sentry from '@sentry/react';
// eslint-disable-next-line no-unused-vars
import { BrowserHistory } from 'history';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';

import { useIsTabletOrMobileMedia } from '~/hooks';
import { useAppContext } from '~/contexts/app';
import { generateCWIdentifierHash } from '~/utils/liveChat';

import faviconIcoFile from '~/assets/icons/favicon/favicon.ico';
import MobileIllustration from '~/assets/images/mobile-support-message/illustration.svg';

import { ParaSmall, TitleSmall } from '../typography';

/**
 *
 * @param {{history: BrowserHistory}} props
 */
function AppRoot({ children, history }) {
  const location = useLocation();
  const theme = useMantineTheme();
  const isTabletOrMobileMedia = useIsTabletOrMobileMedia();
  const { user, organisation } = useAppContext();

  const [isAppUpdateAvailable, setIsAppUpdateAvailable] = useState(false);

  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user.id, email: user.email, name: user.name });
    } else {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
  }, [user]);

  useEffect(() => {
    // To send user attributes to chatwoot
    const sendUserAttributesToChatwoot = () => {
      if (user && organisation) {
        window.$chatwoot.setUser(user.id, {
          name: user.name,
          email: user.email,
          phone_number: user.phoneNumber,
          identifier_hash: generateCWIdentifierHash(user.id),
          company_name: organisation.name,
        });
        window.$chatwoot.setCustomAttributes({
          accountId: user.id,
          org: organisation.name,
        });
      }
    };

    // To check if chatwoot is loaded
    const intervalId = setInterval(() => {
      if (window.$chatwoot) {
        sendUserAttributesToChatwoot();
        clearInterval(intervalId);
      }
    }, 400);

    return () => {
      clearInterval(intervalId);
    };
  }, [user, organisation]);

  // Listen for `UPDATE_AVAILABLE` broadcast event
  useEffect(() => {
    if ('BroadcastChannel' in window) {
      const channel = new BroadcastChannel('sw-broadcast-update');
      channel.onmessage = (event) => {
        if (event.data.type === 'UPDATE_AVAILABLE') {
          setIsAppUpdateAvailable(true);
        }
      };
    }
  }, []);

  // Do a full page reload during navigation if an app update is available
  useEffect(() => {
    const unregister = history.listen(({ location: { pathname, search, hash } }) => {
      if (isAppUpdateAvailable) {
        const url = `${pathname}${search}${hash}`;
        window.location.href = url;
      }
    });
    return unregister;
  }, [isAppUpdateAvailable]);

  const pathName = location.pathname;
  const routesNotSupportingMobileVersion = ['/app/talent-pool', '/app/collections', '/app/jobs'];
  const isMobileVersionNotAvailable = routesNotSupportingMobileVersion.some((route) => pathName.startsWith(route));

  let nodeToRender;

  if (isTabletOrMobileMedia && isMobileVersionNotAvailable) {
    nodeToRender = (
      <Flex h="100vh" justify="center" align="center" style={{ flexDirection: 'column' }} px="24px">
        <MobileIllustrationWrapper>
          <MobileIllustration />
        </MobileIllustrationWrapper>
        <TitleSmall mt="84px">Kello is not on mobile yet</TitleSmall>
        <ParaSmall ta="center" mt="8px">
          For the best experience with kello, please switch to a laptop or desktop.
        </ParaSmall>
      </Flex>
    );
  } else {
    nodeToRender = children;
  }

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Kello</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content="#000000" />
        <link rel="alternate icon" href={faviconIcoFile} />
      </Helmet>
      <Global />
      {nodeToRender}
    </ThemeProvider>
  );
}

export default AppRoot;

const MobileIllustrationWrapper = styled(Box)`
  width: 180px;
  height: 172px;
  flex-shrink: 0;

  > svg {
    width: 100%;
    height: 100%;
  }
`;
