import { modals } from '@mantine/modals';

import { MODAL_IDS } from '~/constants';

export const openSearchHistoryModal = (args) => {
  return modals.openContextModal({
    modal: MODAL_IDS.SEARCH_HISTORY,
    size: '1116px',
    withCloseButton: false,
    styles: {
      content: {
        borderRadius: '16px',
        background: '#fff',
        border: '1px solid #efeff0',
        boxShadow:
          '0px 101px 28px 0px rgba(0, 0, 0, 0), 0px 65px 26px 0px rgba(0, 0, 0, 0), 0px 36px 22px 0px rgba(0, 0, 0, 0.02), 0px 16px 16px 0px rgba(0, 0, 0, 0.03), 0px 4px 9px 0px rgba(0, 0, 0, 0.03)',
        overflow: 'hidden',
      },
      body: {
        padding: '0px',
        overflow: 'hidden',
      },
    },
    ...args,
  });
};

export const openMessageModal = (args) => {
  return modals.openContextModal({
    size: '416px',
    styles: {
      content: {
        borderRadius: '16px',
        background: '#fff',
        border: '1px solid #efeff0',
      },
      header: {
        position: 'absolute',
        width: '100%',
        background: 'transparent',
      },
      body: { padding: '40px' },
    },
    ...args,
  });
};

export const openInviteTeammatesModal = (args) => {
  return modals.openContextModal({
    modal: MODAL_IDS.INVITE_TEAM_MATES_MODAL,
    size: '600px',
    styles: {
      content: {
        borderRadius: '16px',
        background: '#fff',
        border: '1px solid #efeff0',
        boxShadow:
          '0px 101px 28px 0px rgba(0, 0, 0, 0), 0px 65px 26px 0px rgba(0, 0, 0, 0), 0px 36px 22px 0px rgba(0, 0, 0, 0.02), 0px 16px 16px 0px rgba(0, 0, 0, 0.03), 0px 4px 9px 0px rgba(0, 0, 0, 0.03)',
      },
      header: {
        padding: '24px',
        borderBottom: '1px solid #efeff0',
      },
      title: {
        color: '#16151C',
        fontSize: '18px',
        fontWeight: '650',
      },
      body: {
        padding: '24px',
      },
    },
    ...args,
  });
};

export const openCollectionActionModal = (args) => {
  return modals.openContextModal({
    modal: MODAL_IDS.COLLECTION_ACTION_MODAL,
    size: '600px',
    styles: {
      content: {
        borderRadius: '16px',
        background: '#fff',
        border: '1px solid #efeff0',
        boxShadow:
          '0px 101px 28px 0px rgba(0, 0, 0, 0), 0px 65px 26px 0px rgba(0, 0, 0, 0), 0px 36px 22px 0px rgba(0, 0, 0, 0.02), 0px 16px 16px 0px rgba(0, 0, 0, 0.03), 0px 4px 9px 0px rgba(0, 0, 0, 0.03)',
      },
      header: {
        padding: '24px',
        borderBottom: '1px solid #efeff0',
      },
      title: {
        color: '#16151C',
        fontSize: '18px',
        fontWeight: '650',
      },
      body: {
        padding: '0px',
      },
    },
    ...args,
  });
};

export const openConfirmationModal = (args) => {
  return modals.openContextModal({
    modal: MODAL_IDS.CONFIRMATION_MODAL,
    size: '600px',
    styles: {
      content: {
        borderRadius: '16px',
        background: '#fff',
        border: '1px solid #efeff0',
        boxShadow:
          '0px 101px 28px 0px rgba(0, 0, 0, 0), 0px 65px 26px 0px rgba(0, 0, 0, 0), 0px 36px 22px 0px rgba(0, 0, 0, 0.02), 0px 16px 16px 0px rgba(0, 0, 0, 0.03), 0px 4px 9px 0px rgba(0, 0, 0, 0.03)',
      },
      header: {
        padding: '24px',
        borderBottom: '1px solid #efeff0',
      },
      title: {
        color: '#16151C',
        fontSize: '18px',
        fontWeight: '650',
      },
      body: {
        padding: '0px',
      },
    },
    ...args,
  });
};

export const openSearchFiltersModal = (args) => {
  return modals.openContextModal({
    modal: MODAL_IDS.SEARCH_FILTERS_MODAL,
    size: '520px',
    withCloseButton: false,
    styles: {
      content: {
        borderRadius: '16px',
        background: '#fff',
        border: '1px solid #efeff0',
        boxShadow:
          '0px 101px 28px 0px rgba(0, 0, 0, 0), 0px 65px 26px 0px rgba(0, 0, 0, 0), 0px 36px 22px 0px rgba(0, 0, 0, 0.02), 0px 16px 16px 0px rgba(0, 0, 0, 0.03), 0px 4px 9px 0px rgba(0, 0, 0, 0.03)',
        overflow: 'hidden',
      },
      body: {
        padding: '0px',
        overflow: 'hidden',
      },
    },
    ...args,
  });
};

export const openPinJobsModal = (args) => {
  return modals.openContextModal({
    modal: MODAL_IDS.PIN_JOBS_MODAL,
    size: '600px',
    withCloseButton: false,
    padding: '0px',
    styles: {
      content: {
        borderRadius: '16px',
        background: '#fff',
        border: '1px solid #efeff0',
        boxShadow:
          '0px 101px 28px 0px rgba(0, 0, 0, 0), 0px 65px 26px 0px rgba(0, 0, 0, 0), 0px 36px 22px 0px rgba(0, 0, 0, 0.02), 0px 16px 16px 0px rgba(0, 0, 0, 0.03), 0px 4px 9px 0px rgba(0, 0, 0, 0.03)',
        overflow: 'hidden',
      },
    },
    ...args,
  });
};

export const openJobDetailsModal = (args) => {
  return modals.openContextModal({
    modal: MODAL_IDS.JOB_DETAILS_MODAL,
    withCloseButton: false,
    size: '850px',
    padding: '0px',
    styles: {
      content: {
        borderRadius: '16px',
        background: '#fff',
        border: '1px solid #efeff0',
        boxShadow:
          '0px 101px 28px 0px rgba(0, 0, 0, 0), 0px 65px 26px 0px rgba(0, 0, 0, 0), 0px 36px 22px 0px rgba(0, 0, 0, 0.02), 0px 16px 16px 0px rgba(0, 0, 0, 0.03), 0px 4px 9px 0px rgba(0, 0, 0, 0.03)',
        overflow: 'hidden',
      },
    },
    ...args,
  });
};

export const openConfigureJobStagesModal = (args) => {
  return modals.openContextModal({
    modal: MODAL_IDS.CONFIGURE_JOB_STAGES_MODAL,
    withCloseButton: false,
    size: '600px',
    padding: '0px',
    styles: {
      content: {
        borderRadius: '16px',
        overflow: 'visible',
      },
    },
    ...args,
  });
};

export const openVideoModal = (args) => {
  return modals.openContextModal({
    modal: MODAL_IDS.VIDEO_MODAL,
    size: '800px',
    withCloseButton: false,
    styles: {
      content: {
        borderRadius: '16px',
        background: '#fff',
        border: '1px solid #efeff0',
        boxShadow:
          '0px 101px 28px 0px rgba(0, 0, 0, 0), 0px 65px 26px 0px rgba(0, 0, 0, 0), 0px 36px 22px 0px rgba(0, 0, 0, 0.02), 0px 16px 16px 0px rgba(0, 0, 0, 0.03), 0px 4px 9px 0px rgba(0, 0, 0, 0.03)',
      },
      header: {
        padding: '24px',
        borderBottom: '1px solid #efeff0',
      },
      title: {
        color: '#16151C',
        fontSize: '18px',
        fontWeight: '650',
      },
      body: {
        padding: '0px',
      },
    },
    ...args,
  });
};
