import { notifications } from '@mantine/notifications';

import theme from '~/styles/theme';

import { deepMergeObjects } from '.';

const notificationStyles = {
  root: {
    backgroundColor: 'rgba(25, 22, 16, 0.7)',
    backdropFilter: 'blur(4px)',
    padding: '18px 8px 18px 24px',
    borderRadius: '8px',
  },
  title: {
    color: theme.app.colors.TEXT_INVERTED,
  },
  description: {
    color: theme.app.colors.TEXT_INVERTED,
  },
  closeButton: {
    color: theme.app.colors.ICON_NEUTRAL_WEAKEST,
    backgroundColor: 'transparent',
  },
};

export const notifySuccess = ({ message, autoClose = 3000, styles = {}, ...rest }) => {
  const mergedStyles = deepMergeObjects(notificationStyles, styles);
  notifications.show({
    message,
    autoClose,
    color: theme.app.colors.BG_POSITIVE_STRONG,
    styles: mergedStyles,
    ...rest,
  });
};

export const notifyError = ({ message, autoClose = 3000, styles = {}, ...rest }) => {
  const mergedStyles = deepMergeObjects(notificationStyles, styles);
  notifications.show({
    message,
    autoClose,
    color: theme.app.colors.BG_NEGATIVE_NORMAL,
    styles: mergedStyles,
    ...rest,
  });
};

export const notifyWarning = ({ message, autoClose = 3000, styles = {}, ...rest }) => {
  const mergedStyles = deepMergeObjects(notificationStyles, styles);
  notifications.show({
    message,
    autoClose,
    color: theme.app.colors.BG_ACCENT_NORMAL,
    styles: mergedStyles,
    ...rest,
  });
};

export const notifyInfo = ({ message, autoClose = 3000, styles = {}, ...rest }) => {
  const mergedStyles = deepMergeObjects(notificationStyles, styles);
  notifications.show({
    message,
    autoClose,
    color: theme.app.colors.BG_INFO_NORMAL,
    styles: mergedStyles,
    ...rest,
  });
};

export const notifyNeutral = ({ message, autoClose = 3000, styles = {}, ...rest }) => {
  const mergedStyles = deepMergeObjects(notificationStyles, styles);
  notifications.show({
    message,
    autoClose,
    color: theme.app.colors.BG_NEUTRAL_WEAKER,
    styles: mergedStyles,
    ...rest,
  });
};
