import queryString from 'query-string';

import httpClient from './client';

export const fetchJobsForOrg = ({ orgId, pageNumber, pageSize, status, apiVersion = 'v2' }, options = {}) => {
  const query = queryString.stringify({ orgId, pageNumber, pageSize, status });

  return httpClient.get(`/api/${apiVersion}/job?` + query, options);
};

export const fetchJobDetails = ({ jobSlug, apiVersion = 'v2' }, options = {}) => {
  return httpClient.get(`/api/${apiVersion}/job/${jobSlug}/`, options);
};

export const fetchFiltersForJob = ({ jobSlug, apiVersion = 'v2' }, options = {}) => {
  return httpClient.get(`/api/${apiVersion}/job/${jobSlug}/filters/`, options);
};

// V1 APIS
export const saveJobConfig = ({ jobId, payload, apiVersion = 'v1' }, options = {}) => {
  return httpClient.post(`/api/${apiVersion}/job/${jobId}/action-config/`, payload, options);
};

export const updateJobConfig = ({ jobId, payload, apiVersion = 'v1' }, options = {}) => {
  return httpClient.patch(`/api/${apiVersion}/job/${jobId}/action-config/`, payload, options);
};

export const fetchJobSearchHistory = ({ jobId, pageNumber, pageSize, apiVersion = 'v1' }, options = {}) => {
  const query = queryString.stringify({ pageNumber, pageSize });

  return httpClient.get(`/api/${apiVersion}/job/${jobId}/search-history/?` + query, options);
};

export const updateJobSearchHistory = ({ jobId, searchHistoryId, payload, apiVersion = 'v1' }, options = {}) => {
  return httpClient.patch(`/api/${apiVersion}/job/${jobId}/search-history/${searchHistoryId}`, payload, options);
};

export const fetchJobSearchHistoryById = ({ searchHistoryId, apiVersion = 'v1' }, options = {}) => {
  return httpClient.get(`/api/${apiVersion}/job/search-history/${searchHistoryId}`, options);
};

export const deletePinFromJob = ({ jobId, apiVersion = 'v1' }, options = {}) => {
  return httpClient.delete(`/api/${apiVersion}/job/${jobId}/pin`, options);
};

export const pinJob = ({ jobId, apiVersion = 'v1' }, options = {}) => {
  return httpClient.post(`/api/${apiVersion}/job/${jobId}/pin`, options);
};

export const fetchPinnedJobs = ({ apiVersion = 'v1' }, options = {}) => {
  return httpClient.get(`/api/${apiVersion}/job/pinned/`, options);
};

export const fetchAllJobs = ({ apiVersion = 'v1', query = '' }, options = {}) => {
  return httpClient.get(`/api/${apiVersion}/job/all?title=${query}`, options);
};
