import * as Sentry from '@sentry/react';
// import SentryRRWeb from '@sentry/rrweb';
import { Integrations } from '@sentry/tracing';

import * as utils from '~/utils';

export function initSentry({ history }) {
  const { hostname } = window.location;
  const isProductionWebsite = utils.isProductionHostname(hostname);
  const isStagingWebsite = utils.isStagingHostname(hostname);

  let environment = 'dev';
  let debug = true;
  let sampleRate = 0;
  let tracesSampleRate = 0;

  if (isProductionWebsite) {
    environment = 'production';
    debug = false;
    sampleRate = 1;
    tracesSampleRate = 1;
  } else if (isStagingWebsite) {
    environment = 'staging';
    debug = false;
    sampleRate = 1;
    tracesSampleRate = 1;
  }

  Sentry.init({
    dsn: 'https://1c12cc3dcab233f3ca3a6fe0e13b96b9@o289247.ingest.us.sentry.io/4507906371682304',
    environment,
    debug,
    normalizeDepth: 10,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      // new SentryRRWeb({
      //   maskAllInputs: false,
      // }),
    ],
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (error && error.message && error.message.match(/ChunkLoadError/i)) {
        return;
      }
      return event;
    },
    sampleRate,
    tracesSampleRate,
    // Based on https://gist.github.com/impressiver/5092952
    ignoreErrors: [
      'ChunkLoadError',
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      /jigsaw is not defined/,
      /ComboSearch is not defined/,
      /vid_mate_check is not defined/,
      /ibFindAllVideos/,
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
      // Avast extension error
      '_avast_submit',
    ],
    allowUrls: [
      /https?:\/\/www\.kello\.ai/i,
      /https?:\/\/app\.kello\.ai/i,
      /https?:\/\/kello\.ai/i,
      /https?:\/\/app\.cdn\.kello\.ai/i,
      /https?:\/\/staging\.kello\.ai/i,
      /https?:\/\/app\.cdn\.staging\.kello\.ai/i,
      /https?:\/\/demo\.kello\.ai/i,
    ],
    denyUrls: [
      // Google Adsense
      /pagead\/js/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      /newtabs\.org/i,
    ],
  });

  Sentry.setTag('rrweb.active', 'no');
}
