import { format, isDate } from 'date-fns';
import queryString from 'query-string';

import { DATE_FORMATS, PAGE_SIZE_FOR_SEARCH_RESULTS } from '~/constants';

import httpClient from './client';

const isValidDateRangeValues = (values = []) => {
  return Array.isArray(values) && isDate(values[0]) && isDate(values[1]);
};

export const fetchJobApplicationById = ({ jobApplicationId, apiVersion = 'v1' }, options = {}) => {
  return httpClient.get(`/api/${apiVersion}/job-application/${jobApplicationId}/`, options);
};

export const fetchFilteredJobApplications = (payload, options = {}) => {
  const queryObj = { ...payload };

  if (queryObj.workExperienceInYears?.length && Array.isArray(queryObj.workExperienceInYears)) {
    queryObj['workExperienceInYears.comparison'] = 'between';
    queryObj['workExperienceInYears.values'] = queryObj.workExperienceInYears.join(',');
  }

  if (isValidDateRangeValues(queryObj.sourcedAt)) {
    queryObj['sourcedAt.comparison'] = 'between';
    queryObj['sourcedAt.values'] = queryObj.sourcedAt.map((date) => format(date, DATE_FORMATS.csvDate)).join(',');
  }

  delete queryObj['workExperienceInYears'];
  delete queryObj['sourcedAt'];

  // TODO: Handle last interaction date range
  const query = queryString.stringify(queryObj);

  return httpClient.get(`/api/v1/job-application?` + query, options);
};

export const fetchFilteredJobApplicationsCount = (payload, options = {}) => {
  const queryObj = {
    ...payload,
  };

  if (queryObj.workExperienceInYears?.length && Array.isArray(queryObj.workExperienceInYears)) {
    queryObj['workExperienceInYears.comparison'] = 'between';
    queryObj['workExperienceInYears.values'] = queryObj.workExperienceInYears.join(',');
  }

  if (isValidDateRangeValues(queryObj.sourcedAt)) {
    queryObj['sourcedAt.comparison'] = 'between';
    queryObj['sourcedAt.values'] = queryObj.sourcedAt.map((date) => format(date, DATE_FORMATS.csvDate)).join(',');
  }

  delete queryObj['workExperienceInYears'];
  delete queryObj['sourcedAt'];

  // TODO: Handle last interaction date range
  const query = queryString.stringify(queryObj);

  return httpClient.get(`/api/v1/job-application/count?` + query, options);
};

// Actions: ARCHIVE, SHORTLIST, REVIEW_LATER
export const makeActionOnJobApplication = ({ jobApplicationId, payload, apiVersion = 'v1' }, options = {}) => {
  return httpClient.patch(`/api/${apiVersion}/job-application/${jobApplicationId}/action`, payload, options);
};

// Actions: ARCHIVE, REVIEW_LATER
export const undoActionOnJobApplication = ({ jobApplicationId, action, apiVersion = 'v1' }, options = {}) => {
  return httpClient.delete(`/api/${apiVersion}/job-application/${jobApplicationId}/action/${action}`, options);
};

export const fetchReviewLaterJobApplications = (
  { jobId, pageSize = PAGE_SIZE_FOR_SEARCH_RESULTS, pageNumber = 1, filters = {}, apiVersion = 'v1' },
  options = {},
) => {
  const queryObj = {
    pageNumber,
    pageSize,
    jobId,
    ...filters,
  };

  if (isValidDateRangeValues(queryObj.addedOn)) {
    queryObj['addedOn.comparison'] = 'between';
    queryObj['addedOn.values'] = queryObj.addedOn.map((date) => format(date, DATE_FORMATS.csvDate)).join(',');
  }

  const query = queryString.stringify(queryObj);

  return httpClient.get(`/api/${apiVersion}/job-application/review-later?${query}`, options);
};

export const fetchShortlistedJobApplications = (
  { jobId, pageSize = PAGE_SIZE_FOR_SEARCH_RESULTS, pageNumber = 1, filters = {}, apiVersion = 'v1' },
  options = {},
) => {
  const queryObj = {
    pageNumber,
    pageSize,
    jobId,
    ...filters,
  };

  if (isValidDateRangeValues(queryObj.shortlistedOn)) {
    queryObj['shortlistedOn.comparison'] = 'between';
    queryObj['shortlistedOn.values'] = queryObj.shortlistedOn
      .map((date) => format(date, DATE_FORMATS.csvDate))
      .join(',');
  }

  const query = queryString.stringify(queryObj);

  return httpClient.get(`/api/${apiVersion}/job-application/shortlisted?${query}`, options);
};

export const likeAJobApplications = ({ jobApplicationId, apiVersion = 'v1' }, options = {}) => {
  return httpClient.patch(`/api/${apiVersion}/job-application/${jobApplicationId}/like`, options);
};

export const unlikeAJobApplications = ({ jobApplicationId, apiVersion = 'v1' }, options = {}) => {
  return httpClient.delete(`/api/${apiVersion}/job-application/${jobApplicationId}/like`, options);
};

export const addCandidateToJobApplication = ({ jobApplicationId, payload, apiVersion = 'v1' }, options = {}) => {
  return httpClient.post(`/api/${apiVersion}/job-application/${jobApplicationId}/clone`, payload, options);
};
