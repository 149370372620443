export const orange = {
  10: '#FFF5F0',
  20: '#FFE1D0',
  30: '#FFCCAF',
  40: '#FFB78F',
  50: '#F89E6B',
  60: '#D68253',
  70: '#B4683E',
  80: '#92502C',
  90: '#703A1D',
  100: '#4E2710',
};

export const blue = {
  10: '#F4F7FF',
  20: '#D2E1FD',
  30: '#A5C3FB',
  40: '#78A5FA',
  50: '#4B87F8',
  60: '#1E69F6',
  70: '#1854C5',
  80: '#123F94',
  90: '#0C2A62',
  100: '#061531',
};

export const grey = {
  0: '#FFFFFF',
  5: '#F7F6F4',
  10: '#F1F0EE',
  20: '#E8E7E5',
  30: '#CECCC8',
  40: '#B5B2AB',
  50: '#9B9790',
  60: '#827E76',
  70: '#69655D',
  80: '#4F4B44',
  90: '#3F3C36',
  95: '#24221F',
  100: '#191610',
};

export const red = {
  10: '#FDF5F5',
  20: '#F8D6D6',
  30: '#F2ADAC',
  40: '#EB8583',
  50: '#E55C59',
  60: '#DE3330',
  70: '#B22926',
  80: '#851F1D',
  90: '#591413',
  100: '#2C0A0A',
};

export const green = {
  10: '#EFF6F2',
  20: '#D5E6DC',
  30: '#B8D6C3',
  40: '#9BC6AB',
  50: '#7EB693',
  60: '#62A67B',
  70: '#499666',
  80: '#347851',
  90: '#285D3E',
  100: '#23322F',
};

export const yellow = {
  10: '#FEFCF2',
  20: '#FCF3CD',
  30: '#FAE79B',
  40: '#F7DA68',
  50: '#F5CE36',
  60: '#F2C204',
  70: '#C29B03',
  80: '#917402',
  90: '#614E02',
  100: '#302701',
};

export const purple = {
  10: '#F7F6FD',
  20: '#DEDAF7',
  30: '#BDB5EF',
  40: '#9C91E7',
  50: '#7B6CDF',
  60: '#5A47D7',
  70: '#4839AC',
  80: '#362B81',
  90: '#241C56',
  100: '#120E2B',
};

export const teal = {
  10: '#F4FEFC',
  20: '#D5FBF4',
  30: '#ABF6E9',
  40: '#80F2DD',
  50: '#56EDD2',
  60: '#2CE9C7',
  70: '#23BA9F',
  80: '#1A8C77',
  90: '#125D50',
  100: '#092F28',
};

export const olive = {
  10: '#FEFDF3',
  20: '#FAF9D0',
  30: '#F5F2A1',
  40: '#F1EC71',
  50: '#ECE542',
  60: '#E7DF13',
  70: '#B9B20F',
  80: '#8B860B',
  90: '#5C5908',
  100: '#2E2D04',
};

export default {
  orange,
  blue,
  grey,
  red,
  green,
  yellow,
  purple,
  teal,
  olive,
};
