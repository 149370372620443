import React from 'react';
import { useLocation, Navigate, Outlet, useParams } from 'react-router-dom';

import { useAppContext } from '~/contexts/app';
import { searchParamsIncludesUtmParameter } from '~/utils';

const ProtectedRoute = ({ redirectPath = '/' }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const { user } = useAppContext();
  const { collectionId } = useParams();

  let searchParamsToSendToIntermediateRoute = '';

  if (searchParamsIncludesUtmParameter()) {
    searchParamsToSendToIntermediateRoute = location.search;
  }

  const intermediateRouteSearchParams = new URLSearchParams(searchParamsToSendToIntermediateRoute);
  const nextRouteSearchParams = new URLSearchParams(location.search);

  const nextPath = `${pathname}?${nextRouteSearchParams.toString()}`;
  intermediateRouteSearchParams.set('next_path', nextPath);
  if (collectionId) {
    intermediateRouteSearchParams.set('collection_id', collectionId);
  }

  let pathToRedirectUser = redirectPath;

  const isWelcomePage = pathname === '/welcome';

  if (user) {
    if (user.isOnboardingAllowed && user.isOnboarded) {
      return <Outlet />;
    } else if (isWelcomePage) {
      return <Outlet />;
    } else {
      pathToRedirectUser = '/welcome';
    }
  }

  return (
    <Navigate
      to={{
        pathname: pathToRedirectUser,
        search: `?${intermediateRouteSearchParams.toString()}`,
      }}
    />
  );
};

export default ProtectedRoute;
