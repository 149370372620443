import * as rudderanalytics from 'rudder-sdk-js';

export default function rudderAnalytics(pluginConfig) {
  return {
    name: 'rudder-analytics',
    config: {
      ...pluginConfig,
    },
    initialize: ({ config }) => {
      const { writeKey, dataPlaneUrl, ...restConfig } = config;
      if (!writeKey) {
        throw new Error('No RudderStack writeKey');
      }
      if (!dataPlaneUrl) {
        throw new Error('No RudderStack dataPlaneUrl');
      }
      rudderanalytics.load(writeKey, dataPlaneUrl, { ...restConfig });
    },
    identify: ({ payload }) => {
      const { userId, traits, options } = payload;

      if (typeof userId === 'string') {
        rudderanalytics.identify(userId, traits, {
          ...options,
        });
      } else {
        rudderanalytics.identify(traits, {
          ...options,
        });
      }
    },
    page: ({ payload }) => {
      const properties = payload.properties || {};
      const name = properties.name || properties.title;

      rudderanalytics.page(name, properties, {
        ...payload.options,
      });
    },
    track: ({ payload }) => {
      const properties = { ...payload.properties };
      // Remove userId and anonymousId from event properties to ensure
      // there is no mismatch in destinations
      delete properties.userId;
      delete properties.anonymousId;
      rudderanalytics.track(payload.event, properties, {
        ...payload.options,
      });
    },
  };
}
